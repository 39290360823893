x
<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component represents a contact form section, including a Lottie animation of a paper plane as a visual introduction.
The form collects basic information like name, email, and organization type, and once validated, allows users to proceed
further by clicking the "Continue" button. This form is particularly designed to invite users to initiate a project discussion.

How to use it:
<ContactUsFooter
  :id="'contact-form-1'"
  :show-paper-plane-animation="true"
  :paper-plane-json="PaperPlaneJSON"
  :paper-plane-json-mobile="PaperPlaneMobileJSON"
  @open-form="handleFormSubmit"
  @end-animation="onAnimationEnd"
/>
Include this component where a contact form with introductory animation is needed. The props control whether the paper plane
animation is shown and provide different Lottie animations for desktop and mobile. Use the `open-form` event to handle the data
submitted by the form, and the `end-animation` event to handle actions once the paper plane animation finishes.
-->

<template>
  <section>
    <section
      v-if="!paperPlaneAnimDone && showPaperPlaneAnimation && paperPlaneJson && paperPlaneJsonMobile"
      ref="target"
      class="black-background"
    >
      <LottieAnimation
        ref="paperplane"
        :animation-data="isMobile ? paperPlaneJsonMobile : paperPlaneJson"
        :auto-play="false"
        :loop="false"
        class="paperplane-lottie"
        @complete="onComplete"
      />
    </section>
    <section
      v-else
      id="contact-us-footer"
      class="main-container"
    >
      <section class="contact-container">
        <section class="sub-content">
          <h1 class="heading">
            Hello there.
          </h1>
          <p class="intro">
            If you think we'd be a good fit for your project, we'd love to hear from you. Send us an email with a
            description of your project and we'll get back
            to you as soon as we can.
            <br><br>
            Tip: The more you tell us, the better the value we can add to your project.
          </p>
        </section>
        <form
          class="contact-form"
          @submit.prevent="submitForm"
        >
          <section class="form-row">
            <section class="form-group">
              <label for="name">Your name</label>
              <input
                id="name"
                v-model="formData.name"
                type="text"
                placeholder="Full name"
                autocomplete="name"
              >
              <span v-if="formSubmitted && !formData.name">Name is required.</span>
            </section>
            <section class="form-group">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="formData.email"
                type="email"
                placeholder="mail@example.com"
                autocomplete="email"
              >
              <span v-if="!validEmail">Please enter a valid email address.</span>
            </section>
            <section class="form-group">
              <label for="company">How would you describe your organisation?</label>
              <CustomDropdown
                :options="companyOptions.map((option) => option.value)"
                @select-dropdown-item="formData.company = $event"
              />
            </section>
          </section>
          <section class="form-row">
            <div class="form-group" />
            <div
              type="submit"
              :class="`btn-submit ${!isFormValid ? 'disabled' : ''}`"
              :disabled="!isFormValid"
              @click="submitForm"
            >
              <p>Continue</p>
              <img
                :src="`${!isFormValid ? $cdn('icons/icon_paper_plane.svg') : $cdn('icons/icon_paper_plane_active.svg')}`"
                alt="Submit"
              >
            </div>
            <div class="form-group" />
          </section>
        </form>
      </section>
      <ShowcaseFooter
        :id="id"
        class="showcase-footer"
      />
    </section>
  </section>
</template>

<script setup lang="ts">
import { reactive, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import ShowcaseFooter from './ShowcaseFooter.vue';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { LottieAnimation } from 'lottie-web-vue';
// import PaperPlaneJSON from '../lottie/paperplane.json';
// import PaperPlaneMobileJSON from '../lottie/paperplane-mobile.json';

import CustomDropdown from '../general/CustomDropdown.vue';

const observer = ref<IntersectionObserver | null>(null);

gsap.registerPlugin(ScrollTrigger);
const paperplane = ref();

const props = defineProps({
  id: String,
  showPaperPlaneAnimation: {
    type: Boolean,
    default: false,
  },
  paperPlaneJson: {
    type: Object,
    default: () => { },
  },
  paperPlaneJsonMobile: {
    type: Object,
    default: () => { },
  },
});

const paperPlaneAnimDone = ref(false);
const honeypot = ref('');

function lockScroll() {
  document.body.style.overflow = 'hidden';
}

function unlockScroll() {
  document.body.style.overflow = '';
}

const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

const target = ref(null);

onMounted(() => {
  if (props.showPaperPlaneAnimation) {
    // Create the IntersectionObserver to watch when the target element is in view
    observer.value = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start the GSAP animation when the target is in view
          setupAnimation();

          // Stop observing once the animation has been triggered
          if (observer.value && target.value) {
            observer.value.unobserve(target.value);
          }
        }
      });
    });

    // Start observing the target element
    if (target.value) {
      observer.value.observe(target.value);
    }

    // Assign the height of `.paperplane-lottie` to `.black-background`
    const paperplaneLottie = document.querySelector('.paperplane-lottie') as HTMLElement;
    const blackBackground = document.querySelector('.black-background') as HTMLElement;

    if (paperplaneLottie && blackBackground) {
      const paperplaneHeight = paperplaneLottie.offsetHeight; // Get the height of the paperplane-lottie element
      blackBackground.style.height = `${paperplaneHeight}px`; // Set the height of the black-background element
    }
  }
});

onBeforeUnmount(() => {
  // Cleanup observer when the component is unmounted
  if (observer.value && target.value) {
    observer.value.unobserve(target.value);
    observer.value.disconnect();
  }
});

function onComplete() {
  gsap.to('.black-background', {
    opacity: 0,
    duration: 0.5, // Adjust duration as needed
    ease: 'power2.out',
    onComplete: () => {
      gsap.to(window, { duration: 0.5, scrollTo: { y: '#contact-us-footer', offsetY: 0 } });
      paperPlaneAnimDone.value = true;
      emit('end-animation');
      unlockScroll();
    },
  });
}

function setupAnimation() {
  let trigger = '90% bottom';

  // Adjust trigger position for mobile devices
  if (isMobile.value) {
    trigger = '85% bottom';
  }

  // Determine the correct element based on device type
  const ele = document.querySelector('.black-background');

  // Setup ScrollTrigger only if the element exists
  if (ele) {
    ScrollTrigger.create({
      trigger: ele,
      start: trigger,
      markers: false,
      onEnter: () => {
        if (paperplane.value) {
          lockScroll(); // Lock scroll during animation
          paperplane.value.play(); // Play paperplane animation
        }
      },
    });
    ScrollTrigger.refresh(); // Refresh ScrollTrigger to ensure proper alignment
  }
}

const isFormValid = computed(() => {
  return formData.name && validEmail.value && formData.email.length > 0 && formData.company && honeypot.value === '';
});

const formData = reactive({
  name: '',
  email: '',
  company: '',
});

const companyOptions = ref([
  { value: 'Startup', text: 'Startup' },
  { value: 'Small/Medium Enterprise', text: 'Small/Medium Enterprise' },
  { value: 'Large Corporation', text: 'Large Corporation' },
  { value: 'Non-Profit', text: 'Non-Profit' },
  { value: 'Other', text: 'Other' },
]);

const formSubmitted = ref(false);

const validEmail = computed(() => {
  if (formData.email.length === 0) {
    return true;
  }

  const emailRegex = /^\S+@\S+\.\S+$/;
  return emailRegex.test(formData.email);
});

const emit = defineEmits(['open-form', 'end-animation']);
const submitForm = () => {
  if (!isFormValid.value) {
    return;
  }
  formSubmitted.value = true;
  if (formData.name && validEmail.value && formData.company) {
    // open form modal
    emit('open-form', formData);
  }
};
</script>

<style scoped>
.honeypot {
  visibility: hidden;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  padding-top: 160px;
}

.main-container.on-animation {
  background-color: black;
}

.black-background {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 150vh;
}

.black-background.hidden {
  display: none;
}

.paperplane-lottie {
  height: auto;
  object-fit: cover;
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1700px;
  width: 100%;
  background-color: white;
  padding-top: 160px;
  text-align: center;
  margin-bottom: -40px;
  z-index: 2;
  opacity: 0;
  animation: slideFadeIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  animation-delay: 0.1s;
}

.showcase-footer {
  position: relative;
  z-index: 1;
}

.sub-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1340px;
  width: 100%;
  height: fit-content;
}

.heading {
  color: #242424;
  max-width: 1380px;
  height: auto;
  margin: 0 32px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 200px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  letter-spacing: -10px;
}

.intro {
  max-width: 640px;
  padding-top: 32px;
  padding-bottom: 80px;
  margin: 0 32px;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  line-height: 32px;
  /* 160% */
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 48px;
  padding-bottom: 160px;
  max-width: 1380px;
  margin: 0 24px;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.form-group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.form-group span {
  color: #dc4664;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding-top: 12px;
}

.form-group label {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  line-height: 32px;
  margin-bottom: 12px;
}

.form-group input,
.form-group select {
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #dcdcdc);
  background-color: #fff;
}

.form-group input:focus,
.form-group select:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 64px;
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) center;
  background-size: 16px 16px;
}

/* For Internet Explorer 10+ */
.form-group select::-ms-expand {
  display: none;
}

.btn-submit {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 64px;
  border-radius: 12px;
  justify-content: space-between;
  background-color: #007aff;
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding: 16px 24px;
  line-height: 32px;
}

.btn-submit:hover {
  opacity: 0.8;
}

.btn-submit.disabled {
  border: 1px solid var(--Neutrals-Gainsboro, #dcdcdc);
  background-color: transparent;
  color: #969696;
  cursor: not-allowed;
}

@media screen and (max-width: 1380px) {
  .honeypot {
    display: none !important;
  }

  .form-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
  }

  .contact-form {
    max-width: 640px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .form-row {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 820px) {
  .main-container {
    padding-top: 64px;
  }

  .contact-container {
    width: 92%;
    padding-top: 64px;
    margin-bottom: -24px;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    gap: 0;
  }

  .heading {
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -3px;
    max-width: 310px;
    margin: 0 24px;
  }

  .intro {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.25px;
    padding-top: 24px;
    padding-bottom: 64px;
    margin: 0 24px;
  }

  .form-group label {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 12px;
    text-align: start;
  }

  .form-group select {
    margin-bottom: 8px;
    background-position: calc(100% - 20px) center;
    background-size: 12px 12px;
  }

  .form-group input,
  .form-group select {
    height: 48px;
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    padding: 12px 24px;
    border-radius: 10px;
  }

  .form-group span {
    font-size: 14px;
    padding-top: 12px;
  }

  .form-row {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .btn-submit {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    padding: 12px 24px;
    height: 48px;
    border-radius: 10px;
  }

  .btn-submit img {
    width: 24px;
    height: 24px;
  }
}
</style>
