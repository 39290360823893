<template>
  <div>
    <BlankScreen v-show="showForm" />
    <Transition name="fade">
      <AppBarDark
        v-if="darkMode"
        :class="`${showForm ? 'hidden' : ''}`"
        @get-in-touch="showForm = true"
      />
      <AppBarLight
        v-else
        :class="`${showForm ? 'hidden' : ''}`"
        :initial-expand="false"
        @get-in-touch="showForm = true"
      />
    </Transition>

    <HeroVue id="topEle" />

    <WelcomeMessage
      v-if="showContent('/')"
      id="target"
      ref="ref1"
    />
    <WelcomeMessageAU
      v-if="showContent('/au/')"
      id="target"
      ref="ref2"
      @get-in-touch="openForm"
    />
    <WelcomeMessageSG
      v-if="showContent('/sg/')"
      id="target"
      ref="ref2"
      @get-in-touch="openForm"
    />
    <StayAheadMessage
      v-if="showContent('/au/')"
      id="target"
      ref="ref3"
      class="sgDesktop:tw-sticky tw-top-0"
    />
    <StayAheadMessage
      v-if="showContent('/sg/')"
      id="target"
      ref="ref3"
      class="sgDesktop:tw-sticky tw-top-0"
    />
    <BuilderMessage
      v-if="mainSite"
      ref="ref5"
    />
    <BigSmallMessage
      v-if="!endBigSmallAnimation && mainSite"
      ref="ref6"
      @end-animation="endOfAnimation"
    />

    <div
      v-else
      class="tw-bg-black tw-relative tw-z-50"
    >
      <BigSmallMessageNoAnimation
        v-if="endBigSmallAnimation && mainSite"
        ref="ref7"
      />
      <ProjectShowcase
        :key="workKey"
        ref="ref8"
        :dark-mode="true"
        :grid-items="showcase"
      />
      <ClientIconsDark
        :key="workKey"
        ref="ref9"
        :data="works"
        @show-paperplane="resetPaperplaneKey"
        @on-change-list="resetPaperplaneKey"
      />
      <ContactUsFooter
        :key="paperplaneKey"
        ref="ref10"
        :show-paper-plane-animation="true"
        :paper-plane-json="paperplane"
        :paper-plane-json-mobile="paperplaneMobile"
        @open-form="handleFormRequest"
        @end-animation="endPaperPlaneAnimation = true"
      />
      <Footer
        v-show="endPaperPlaneAnimation"
        :works-data="worksFooter"
        @get-in-touch="openForm"
      />
    </div>

    <!-- CONTACT US FORM -->
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <PrivacyPolicyBanner />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, inject, reactive } from 'vue';
import gsap from 'gsap';
import BlankScreen from '../components/general/BlankScreen.vue';
import ScrollTrigger from 'gsap/ScrollTrigger';
import AppBarDark from '../components/general/AppBarDark.vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import HeroVue from '../components/home/Hero.vue';
import WelcomeMessage from '../components/home/WelcomeMessage.vue';
import BuilderMessage from '../components/home/BuilderMessage.vue';
import StayAheadMessage from '../components/home/StayAheadMessage.vue';
import BigSmallMessage from '../components/home/BigSmallMessage.vue';
import BigSmallMessageNoAnimation from '../components/home/BigSmallMessageNoAnimation.vue';
import ProjectShowcase from '../components/project/ProjectShowcase.vue';
import ClientIconsDark from '../components/home/ClientIconsDark.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Footer from '../components/general/Footer.vue';
import WelcomeMessageAU from '../components/home/WelcomeMessageAU.vue';
import WelcomeMessageSG from '../components/home/WelcomeMessageSG.vue';
import PrivacyPolicyBanner from '../components/general/PrivacyPolicyBanner.vue';
import { uuid } from 'vue3-uuid';
import axios from 'axios';
import { cdn } from '../support/cdn';

// Constants and reactive variables
const darkMode = ref(true);
const showForm = ref(false);
const endBigSmallAnimation = ref(false);
const worksFooter = ref<Work[]>([]);
const blogsFooter = ref<BlogMeta>({ show_see_more: false, items: [] });
const wpData = inject('wordpressData');
const mainSite = computed(() => wpData?.value?.is_main_site ?? true);
const siteID = computed(() => wpData?.value?.blog_id ?? 1);
const sitePath = computed(() => wpData?.value?.multisite_path ?? '/');
const endPaperPlaneAnimation = ref(false);
const paperplaneKey = ref(uuid.v4());
const workKey = ref(uuid.v4());
const paperplane = ref();
const paperplaneMobile = ref();
const isMobile = ref(window.innerWidth <= 820);

const hallOfFameInView = ref(false);

const worksData = reactive({
  items: [],
  currentPage: 1,
  hasNextPage: true,
  isLoading: false,
});

const currentPage = ref(1);  // Start from page 1
const hasNextPage = ref(true);

const props = withDefaults(
  defineProps<{
    wordpressData?: any | null;
  }>(),
  {
    wordpressData: null,
  }
);

const showcase = computed(() => {
  let parsedData = [];

  try {
    parsedData = JSON.parse(props.wordpressData.featured_work);
  } catch (error) {
    console.error('Failed to parse featured_work:', error);
  }

  return parsedData;
});

const works = computed(() => worksData.items);

const contactInfo = {
  name: '',
  email: '',
  company: '',
};

// Interfaces
interface BlogMeta {
  show_see_more: boolean;
  items: Blog[];
}

interface Blog {
  title: string;
  permalink: string;
}

interface Work {
  title: string;
  permalink: string;
}

// Function initializations
function showContent(path: string, ref?: any): boolean {
  return path.trim() === sitePath.value.trim();
}

function onResize() {
  isMobile.value = window.innerWidth <= 820;
}

function handleFormRequest(payload: any) {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
}

function openForm() {
  showForm.value = true;
}

function closeForm() {
  showForm.value = false;
}

function resetPaperplaneKey() {
  if (!endPaperPlaneAnimation.value) {
    paperplaneKey.value = uuid.v4();
  }
}

gsap.registerPlugin(ScrollTrigger);

function fetchWorks() {
  // check params is its contain reset_cache
  const params = new URLSearchParams(window.location.search);
  let worksEndpoint = '/wp-json/api/v1/showcase-works?mode=home&site_id=' + siteID.value + '&page=' + currentPage.value;
  if (params.has('reset_cache')) {
    worksEndpoint += '&reset_cache=' + params.get('reset_cache');
  }
  axios.get(worksEndpoint).then((response) => {
    worksData.items.push(...response.data.items);
    currentPage.value++;
    hasNextPage.value = response.data.has_next_page;
    // workKey.value = uuid.v4();
  }).catch((error) => {
    console.error('There was an error fetching the data:', error);
  }).finally(() => {
    setTimeout(() => {
      if (hasNextPage.value) {
        fetchWorks();
      }
    },300);
  });
}

function endOfAnimation(){
  endBigSmallAnimation.value = true;
}

onMounted(() => {
  axios.get(cdn('lottie/paperplane.json')).then((response) => {
    paperplane.value = response.data;
  });

  axios.get(cdn('lottie/paperplane-mobile.json')).then((response) => {
    paperplaneMobile.value = response.data;
  });

  fetchWorks();

  ScrollTrigger.create({
    trigger: '#topEle',
    start: 'bottom 16%', // Adjust start based on screen size
    onEnter: () => {
      darkMode.value = false;
    },
    onLeaveBack: () => {
      darkMode.value = true;
    },
    markers: false, // Enable markers for debugging (remove in production)
  });

  if (props.wordpressData) {
    switch (props.wordpressData.query) {
      case 'contact-us':
        showForm.value = true;
        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.pushState({}, document.title, urlWithoutParams);
        break;
      case 'job':
        const baseUrl = process.env.APP_URL;
        window.location.href = `${baseUrl}/careers/?goto=job-application`;
        break;
    }
  }

  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
template {
  overscroll-behavior: none;
}

.animation-down {
  position: relative;
  top: 100px;
}

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
