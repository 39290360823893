<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Blog Page
Description:
This component is a Swiper-based image carousel for displaying blog images. It supports both single and double image
layouts per slide and provides smooth, continuous autoplay. The component automatically adjusts based on the window size,
maintaining an engaging and dynamic display for users browsing blog images.

How to use it:
<BlogSlider :datas="imageDataArray" />
Pass `datas` as an array of image data objects, where each object includes an array of images.
Each image should have a `src` for the URL and an `alt` for accessibility.
-->

<template>
  <swiper
    v-if="imageBlocks.length > 0"
    :key="swiperKey"
    :slides-per-view="slidesPerView"
    :space-between="pageBetween"
    :loop="true"
    :autoplay="{
      delay: 0,
      disableOnInteraction: false,
    }"
    :speed="8000"
    :prevent-interaction-on-transition="true"
    :load-prev-next="true"
    :lazy-preload-prev-next="slidesPerView"
    :free-mode="true"
    class="mySwiper"
  >
    <swiper-slide
      v-for="(block, index) in imageBlocks"
      :key="index"
    >
      <div
        v-if="block.type === 'single'"
        class="image-container"
      >
        <img
          :src="$cdn(block.images[0].src)"
          :alt="block.images[0].alt"
          class="image-large"
          loading="lazy"
        >
      </div>
      <div class="swiper-lazy-preloader" />
      <div
        v-if="block.type === 'double'"
        class="image-container double"
      >
        <img
          v-for="(image, i) in block.images"
          :key="`double-${index}-${i}`"
          :src="$cdn(image.src)"
          :alt="image.alt"
          class="image-small"
          loading="lazy"
        >
        <div class="swiper-lazy-preloader" />

      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import { uuid } from 'vue3-uuid';
import { generateRandomClassName } from '../../support/helper';
import SwiperCore from 'swiper';
import axios from 'axios';
import { cdn } from '../../support/cdn';

SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

const props = defineProps({
  datas: {
    type: Array,
    default: () => []
  }
});

const processImage = (value: any) => {
  return {
    src: value.image,
    alt: 'image-' + generateRandomClassName()
  };
};

const duplicateItems = (items: any[], minSize: number) => {
  const duplicated = [...items];
  while (duplicated.length < minSize) {
    duplicated.push(...items);
  }
  return duplicated.slice(0, minSize); // Trim the list to exactly the minSize if needed
};

const imageBlocks = computed(() => {
  const adjustedData = duplicateItems(props.datas, 4); // Ensure at least 4 items
  return adjustedData.map((obj: any) => {
    if (obj.images.length > 1) {
      return {
        type: 'double',
        images: obj.images.map((image: any) => processImage(image))
      };
    }
    return {
      type: 'single',
      images: obj.images.map((image: any) => processImage(image))
    };
  });
});

const slidesPerView = ref(3);
const pageBetween = ref(34);
const swiperKey = ref(uuid.v4());

function onResize() {
  swiperKey.value = uuid.v4();
}

onMounted(() => {

  if (window.innerWidth <= 480) {
    slidesPerView.value = 1.5;
    pageBetween.value = 16;
  } else {
    slidesPerView.value = 3;
    pageBetween.value = 34;
  }

  axios.get(cdn('json/services-image-blocks.json')).then((response) => {
    imageBlocks.value = response.data;
  });


  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  height: auto !important;
  align-items: stretch;
}

.image-container{
  display: flex;
  height: 100%;
  width: 100%;
}

.image-container.double {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}


.image-large{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-small {
  width: 100%;
  height: 50%;
}

.swiper-free-mode>.swiper-wrapper {
  transition-timing-function: linear !important;
}


@media screen and (max-width: 820px) {
  .image-container.double {
    gap: 24px;
  }
}

@media screen and (max-width: 480px) {

  .image-container.double {
    gap: 16px;
  }
}
</style>
