// blogService.ts

import axios, { AxiosResponse } from 'axios';
import { BlogResponse } from './model';

const apiUrl = process.env.APP_URL + '/wp-json/api/v1/blogs';

export const getBlogs = async (page: number, perPage: number, blogTag?: string): Promise<BlogResponse> => {
  try {
    const params: any = {
      page: page.toString(),
      per_page: perPage.toString(),
    };

    if (blogTag) {
      params.blog_tag = blogTag;
    }

    const response: AxiosResponse<BlogResponse> = await axios.get(apiUrl, { params });

    return response.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

