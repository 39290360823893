<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: General Components
Description:
This component represents the footer of a website, featuring multiple sections: Services, Work, About, Contact, and locations.
Each section provides links and relevant information, making navigation easier for users.

How to use it:
Include this footer component at the bottom of your application's main layout to provide essential links and contact information.
The footer contains dynamic content, such as the list of works and blog items, fetched from the server.

Example usage:
<Footer @get-in-touch="handleGetInTouch" />
The `get-in-touch` event can be handled by the parent component to open a contact form or navigate the user to a relevant section.
-->

<template>
  <section class="site-footer">
    <div class="footer-widgets footer-widgets-top">
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          SERVICES
        </h4>
        <ul>
          <li><a :href="`${sitePath}services?goto=discovery`">Discovery</a></li>
          <li><a :href="`${sitePath}services?goto=strategy`">Strategy</a></li>
          <li><a :href="`${sitePath}services?goto=design`">Design</a></li>
          <li><a :href="`${sitePath}services?goto=development`">Development</a></li>
          <li><a :href="`${sitePath}services?goto=advisory`">Advisory</a></li>
        </ul>
      </div>

      <!-- Updated with showContent('/') -->
      <div
        v-if="showContent('/')"
        class="footer-widget"
      >
        <h4 class="footer-widget-title">
          FEATURED ARTICLES
        </h4>
        <ul>
          <li
            v-for="(blog, index) in blogs?.items"
            :key="`&quot;project-${index}&quot;`"
          >
            <a :href="blog.permalink">{{ blog.title }}</a>
          </li>
          <li v-if="blogs?.show_see_more">
            <a
              href="/blog"
              class="see-more-text"
            >See more</a>
          </li>
        </ul>
      </div>

      <div class="footer-widget">
        <h4 class="footer-widget-title">
          WORK (SELECTED)
        </h4>
        <ul>
          <li
            v-for="(work, index) in works"
            :key="`&quot;project-${index}&quot;`"
          >
            <a :href="work.permalink">{{ work.title }}</a>
          </li>
        </ul>
      </div>
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          ABOUT
        </h4>
        <ul>
          <li><a :href="`${sitePath}about`">About Alphapod</a></li>
          <!-- Replaced v-if="mainSite" with v-show="showContent('/')" -->
          <li v-show="showContent('/')">
            <a :href="`${sitePath}careers`">Careers</a>
          </li>
          <li v-show="showContent('/')">
            <a :href="`${sitePath}internship-program`">Internship Program</a>
          </li>
        </ul>

        <h4
          v-show="showContent('/')"
          class="footer-widget-title contact-space-sg"
        >
          CONTACT
        </h4>
        <ul v-show="showContent('/')">
          <li><a
            class="get-in-touch"
            @click="emit('get-in-touch')"
          >Get in touch</a></li>
          <li><a href="mailto:hello@alphapod.com">hello@alphapod.com</a></li>
        </ul>
      </div>

      <!-- Updated with showContent('/au/') -->
      <div
        v-show="showContent('/au/')"
        class="footer-widget"
      >
        <h4 class="footer-widget-title">
          CONTACT
        </h4>
        <ul>
          <li><a
            class="get-in-touch"
            @click="emit('get-in-touch')"
          >Get in touch</a></li>
          <li><a href="mailto:hello@alphapod.com">hello@alphapod.com</a></li>
        </ul>
      </div>
      <div
        v-show="showContent('/sg/')"
        class="footer-widget"
      >
        <h4 class="footer-widget-title">
          CONTACT
        </h4>
        <ul>
          <li><a
            class="get-in-touch"
            @click="emit('get-in-touch')"
          >Get in touch</a></li>
          <li><a href="mailto:hello@alphapod.com">hello@alphapod.com</a></li>
        </ul>
      </div>
    </div>

    <hr class="footer-divider">

    <div class="footer-widgets footer-widgets-bottom">
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          MALAYSIA
        </h4>
        <p>
          E-03-08, Sunway Geo Avenue 2<br>
          Jalan Lagoon Selatan, Bandar Sunway<br>
          47500 Subang Jaya<br>
          Selangor, Malaysia.
        </p>
      </div>

      <div class="footer-widget">
        <h4 class="footer-widget-title">
          SINGAPORE
        </h4>
        <p>231 Mountbatten Road<br>Block C Level 3<br>Singapore 397999.</p>
      </div>
      <div class="footer-widget">
        <h4 class="footer-widget-title">
          AUSTRALIA
        </h4>
        <p>8 Monument Street<br>Harrisdale WA 6112,<br>Australia.</p>
      </div>

      <div class="footer-widget footer-widget-align-right">
        <p>© {{ dayjs().format('YYYY') }} Alphapod Sdn. Bhd. All Rights Reserved</p>
        <p>
          <a :href="`${sitePath}privacy-policy`">Privacy Policy</a> |
          <a :href="`${sitePath}cookie-policy`">Cookie Policy</a>
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { computed, inject, onMounted, ref } from 'vue';


function showContent(path: string): boolean {
  return path.trim() === sitePath.value.trim();
}
interface BlogMeta {
  show_see_more: boolean;
  items: Blog[];
}

interface Blog {
  title: string;
  permalink: string;
}

interface Work {
  title: string;
  permalink: string;
}

const props = defineProps({
  worksData: {
    type: Array as () => Work[],
    default: undefined,
  },
});

const emit = defineEmits(['get-in-touch']);
const works = ref<Work[]>([]);
const blogs = ref<BlogMeta>();
const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
const siteID = computed(() => wordpressData?.value?.blog_id ?? 1);
const sitePath = computed(() => wordpressData?.value?.multisite_path ?? '/');



function fetchWorks() {
  axios
    .get('/wp-json/api/v1/footer-works?site_id=' + siteID.value)
    .then((response) => {
      works.value = response.data;
    })
    .catch((error) => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

function fetchBlogs() {
  axios
    .get('/wp-json/api/v1/footer-blogs?site_id=' + siteID.value)
    .then((response) => {
      blogs.value = response.data;
    })
    .catch((error) => {
      // Handle error
      console.error('There was an error fetching the data:', error);
    });
}

onMounted(() => {
  fetchWorks();
  fetchBlogs();
});
</script>

<style scoped>
.contact-space-sg {
  padding-top: 40px;
}

.footer-divider,
.footer-widgets {
  max-width: 1248px;
}

.footer-spacer {
  height: 40px;
}

.footer-widgets-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr 1.2fr;
  gap: 20px;
}

.footer-widget-title {
  margin-bottom: 10px;
  font-weight: 600;
}

.footer-widgets-top {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.get-in-touch {
  cursor: pointer;
}

.see-more-text {
  font-weight: bold;
}

/* Glow effect on hover */
.site-footer a:hover {
  text-decoration: underline;
  transition: text-decoration 0.3s ease-out;
}

.site-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .footer-widgets {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 32px;
  }

  .hidden-container {
    display: none;
  }
}

.footer-widget-title {
  margin-bottom: 10px;
  font-weight: 600;
}

/* @media screen and (max-width: 540px) {
  .site-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 24px;
    width: 100%;
  }
  .footer-widget-title {
  margin-bottom: 8px;
  font-weight: 600;
}

  .footer-widget-align-right,
  .footer-widgets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-widget {
    margin-bottom: 0px;
  }
} */

@media screen and (max-width: 480px) {
  .site-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 24px;
    width: 100%;
  }

  .footer-widget-title {
    margin-bottom: 8px;
    font-weight: 600;
  }

  .footer-widget-align-right,
  .footer-widgets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-widget {
    margin-bottom: 0px;
  }
}
</style>
