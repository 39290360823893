<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Project Page

Description:
This component displays the project showcase section of the Project page. It includes a grid of project tiles
that can be scrolled through and a click event to open the selected project.

How to use it:
<ProjectShowcase
  :left-item="leftItem"
  :right-item="rightItem"
/>
-->
<template>
  <div :class="`showcase-container ${!darkMode ? 'light-background' : ''}`">
    <div
      v-if="gridItems && gridItems.length > 0"
      class="sub-container"
    >
      <div class="column left-item">
        <div
          v-for="(item, index) in leftItem"
          :id="item.id"
          :key="index"
          :enter="{
            scale: 2,
            transition: {
              delay: 500,
            },
          }"
          class="grid-item"
        >
          <a :href="`${item.url}`">
            <div class="image-container">
              <img
                :src="item.acf.feature_image.url"
                :alt="item.acf.feature_image.alt"
                loading="lazy"
              >
            </div>
            <p :class="`title ${!darkMode ? 'light-background' : ''}`">
              {{ item.acf.feature_title }}
            </p>
            <p :class="`subtitle ${!darkMode ? 'light-background' : ''}`">
              {{ item.acf.feature_subtitle }}
            </p>
          </a>
        </div>
      </div>

      <div class="column right-item">
        <div
          v-for="(item, index) in rightItem"
          :id="item.id"
          :key="index"
          :enter="{
            scale: 2,
            transition: {
              delay: 500,
            },
          }"
          class="grid-item"
        >
          <a :href="`${item.url}`">
            <div class="image-container">
              <img
                :src="item.acf.feature_image.url"
                :alt="item.acf.feature_image.alt"
                loading="lazy"
              >
            </div>
            <p :class="`title ${!darkMode ? 'light-background' : ''}`">
              {{ item.acf.feature_title }}
            </p>
            <p :class="`subtitle ${!darkMode ? 'light-background' : ''}`">
              {{ item.acf.feature_subtitle }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <div
      v-else
      class="tw-bg-black tw-h-[100vh] tw-w-[100vw] tw-flex tw-justify-center"
    >
      Loading more content, please wait...
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount, computed, inject } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FeaturedWork } from '../../model/FeaturedWork';

gsap.registerPlugin(ScrollTrigger);

const emit = defineEmits(['end-animation']);
const wordpressData = inject('wordpressData');
const siteID = computed(() => wordpressData?.value?.blog_id ?? 1);

const props = defineProps({
  darkMode: {
    type: Boolean,
    default: false,
  },
  gridItems: {
    type: Array as () => FeaturedWork[],
    default: <FeaturedWork[]>[],
  }
});

// const gridItems = ref<FeaturedWork[]>();

// Process grid items and split into left and right
const leftItem = computed(() => {
  return props.gridItems?.filter((item, index) => index % 2 === 0);
});
const rightItem = computed(() => {
  return  props.gridItems?.filter((item, index) => index % 2 !== 0);
});

// Track whether it's a mobile screen size
const isMobile = ref(false);
function onResize() {
  isMobile.value = window.innerWidth <= 1024;
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
.showcase-container {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 0px;
  transition: opacity 0.5s ease;
  background-color: black;
  position: relative;
  /* Ensure positioning context for z-index */
  z-index: 2;
  /* Default z-index */
}

.showcase-container.light-background {
  background-color: white;
}

.sub-container {
  max-width: 1280px;
  margin: 0px 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.left-item {
  margin-right: 40px;
}

.right-item {
  margin-top: 400px;
  margin-left: 40px;
}

.title {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: white;
  padding-top: 40px;
  padding-bottom: 16px;
}

.title.light-background {
  color: #969696;
}

.grid-item {
  overflow: hidden;
  /* Prevents the container from resizing */
  transition: transform 0.3s ease;
  /* Optional: Smooth transition for the container */
}

.image-container {
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  transition: transform 0.3s ease;
  /* Smooth transition for zoom effect */
  display: block;
  object-fit: cover;
  aspect-ratio: 0.75;
  /* Ensures the image does not have extra space around it */
}

.grid-item img:hover {
  transform: scale(1.05);
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
  color: #969696;
  padding-bottom: 160px;
}

.subtitle.light-background {
  color: #242424;
}

@media screen and (max-width: 820px) {
  .sub-container {
    margin: 0px 80px;
  }

  .left-item {
    margin-right: 32px;
  }

  .right-item {
    margin-top: 109px;
    margin-left: 32px;
  }
}

@media screen and (max-width: 480px) {
  .showcase-container {
    padding-top: 80px;
    z-index: 2;
  }

  .title {
    color: var(--Neutrals-White, #fff);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    padding-top: 24px;
    padding-bottom: 8px;
  }

  .subtitle {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding-bottom: 80px;
  }

  .sub-container {
    margin: 0px 24px;
  }

  .left-item {
    margin-right: 8px;
  }

  .right-item {
    margin-top: 109px;
    margin-left: 8px;
  }
}
</style>
