import PageHome from 'wp-content/themes/ap_vue_base_theme/src/pages/page-home.vue';
import Page404 from 'wp-content/themes/ap_vue_base_theme/src/pages/page-404.vue';
import PageServices from 'wp-content/themes/ap_vue_base_theme/src/pages/page-services.vue';
import PageWorks from 'wp-content/themes/ap_vue_base_theme/src/pages/page-works.vue';
import PageAbout from 'wp-content/themes/ap_vue_base_theme/src/pages/page-about.vue';
import PageCareers from 'wp-content/themes/ap_vue_base_theme/src/pages/page-careers.vue';
import PagePrivacyPolicy from 'wp-content/themes/ap_vue_base_theme/src/pages/page-privacy-policy.vue';
import PageCookiePolicy from 'wp-content/themes/ap_vue_base_theme/src/pages/page-cookie-policy.vue';
import PageProjects from 'wp-content/themes/ap_vue_base_theme/src/pages/page-projects.vue';
import PageBlogs from 'wp-content/themes/ap_vue_base_theme/src/pages/page-blogs.vue';
import PageBlogLanding from 'wp-content/themes/ap_vue_base_theme/src/pages/page-blog-landing.vue';
// import pageDna from 'wp-content/themes/ap_vue_base_theme/src/pages/page-dna.vue'; // if this page no longer valid just remove this line
import PageInternshipProgram from 'wp-content/themes/ap_vue_base_theme/src/pages/page-internship-program.vue';

export default {
  'page-home': PageHome,
  'page-404': Page404,
  'page-services': PageServices,
  'page-works': PageWorks,
  'page-about': PageAbout,
  'page-careers': PageCareers,
  'page-privacy-policy': PagePrivacyPolicy,
  'page-cookie-policy': PageCookiePolicy,
  'page-projects': PageProjects,
  'page-blogs': PageBlogs,
  'page-blog-landing': PageBlogLanding,
  // 'page-dna': pageDna, // if this page no longer valid just remove this line
  'page-internship-program': PageInternshipProgram,
};
