<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: Blog Page
Description:
This component represents a blog card for the Blog Page, displaying essential details about the blog post such as title,
subtitle, estimated reading time, and an image. It includes a "Read more" link that navigates to the full post using the blog's
permalink. The card is styled for an appealing presentation, with separate sections for details and the featured image.

How to use it:
<BlogCard
  :blog="blogItem"
/>
Pass a `blog` object with properties like `title`, `subtitle`, `estimate_time_read`, `image`, and `permalink` to populate the card.
-->
<template>
  <div class="container">
    <div class="sub-container">
      <!-- Left Column -->
      <div class="column left-item">
        <div
          v-if="leftItem.length === 0"
          class="placeholder"
        >
          No items to display in this category.
        </div>
        <div
          v-for="(item, index) in leftItem"
          v-else
          :id="convertToSlug(item.title)"
          :key="index"
          class="grid-item"
        >
          <a :href="`${item.permalink}`">
            <div class="image-container">
              <img
                :src="$cdn(item.image)"
                :alt="item.subtitle"
                loading="lazy"
              >
            </div>
            <div>
              <p class="time-read">{{ item.estimate_time_read }} • {{ item.tags[0] }}</p>
              <p class="title">{{ item.title }}</p>
            </div>
          </a>
        </div>
      </div>

      <!-- Right Column -->
      <div class="column right-item">
        <div
          v-if="rightItem.length === 0"
          class="placeholder"
        >
          No items to display in this category.
        </div>
        <div
          v-for="(item, index) in rightItem"
          v-else
          :id="convertToSlug(item.title)"
          :key="index"
          class="grid-item"
        >
          <a :href="`${item.permalink}`">
            <div class="image-container">
              <img
                :src="$cdn(item.image)"
                :alt="item.title"
                loading="lazy"
              >
            </div>
            <div>
              <p class="time-read">{{ item.estimate_time_read }} • {{ item.tags[0] }}</p>
              <p class="title">{{ item.title }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { BlogItem } from '../../support/model';

// Receive blogs and category as props
const props = defineProps({
  category: {
    type: String,
    default: 'All',
  },
  blogs: {
    type: Array as () => BlogItem[],
    required: true,
  },
});

// Compute left and right columns based on the index of the blogs
const leftItem = computed(() => {
  return props.blogs.filter((_, index) => index % 2 === 0); // Left column takes even-indexed blogs
});

const rightItem = computed(() => {
  return props.blogs.filter((_, index) => index % 2 !== 0); // Right column takes odd-indexed blogs
});


function convertToSlug(text: string): string {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  padding-top: 160px;
  padding-bottom: 0px;
  transition: opacity 0.5s ease;
}

.sub-container {
  max-width: 1280px;
  margin: 0px 48px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  gap: 80px;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right-item {
  margin-top: 400px;
}

.time-read {
  padding-top: 40px;
  color: #969696;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}

.title {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 128.571% */
  letter-spacing: -0.25px;
  padding: 16px 0 160px;
  color: black;
}

.placeholder {
  visibility: hidden;
  /* Text is invisible but still takes up space */
  text-align: center;
  font-style: italic;
  margin: auto;
  user-select: none;
  /* Prevents text selection */
  pointer-events: none;
  /* Prevents click events */
}

.grid-item {
  overflow: hidden;
  transition: transform 0.3s ease;
}

.image-container {
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  display: block;
  aspect-ratio: 0.75;
}

.grid-item img:hover {
  transform: scale(1.05);
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 700;
  padding-bottom: 160px;
  color: #969696;
}

@media screen and (max-width: 1024px) {
  .sub-container {
    gap: 64px;
  }
}

@media screen and (max-width: 820px) {
  .time-read {
    font-size: 18px;
    /* Slightly smaller text for medium screens */
    line-height: 26px;
    /* Adjust line-height */
    padding-top: 30px;
    /* Adjust padding for better fit */
  }

  .title {
    font-size: 28px;
    /* Adjust title font size for medium screens */
    line-height: 30px;
    /* Adjust line-height */
    padding-top: 16px;
    /* Adjust padding */
    padding-bottom: 80px;
  }

  .sub-container {
    margin: 0px 80px;
    position: static;
    /* Remove position on smaller screens */
    left: initial;
    /* Reset left */
    transform: none;
    /* Remove transform */
    min-height: auto;
    /* Remove min-height */
  }

  .right-item {
    margin-top: 109px;
  }
}

@media screen and (max-width: 768px) {

  .sub-container {
    gap: 48px;
  }

  .container {
    padding-top: 80px;
  }

  .sub-container {
    left: 50%;
    /* Position element in the middle */
  }
}

@media screen and (max-width: 480px) {

  .time-read {
    font-size: 14px;
    /* Even smaller text for mobile */
    line-height: 20px;
    /* Adjust line-height */
    padding-top: 24px;
    /* Adjust padding */
  }

  .title {
    font-size: 16px;
    /* Further reduced title size for small screens */
    line-height: 24px;
    /* Adjust line-height */
    padding-top: 8px;
    /* Adjust padding */
    padding-bottom: 80px;
  }

  .subtitle {
    font-size: 14px;
    /* Adjust subtitle for mobile screens */
    line-height: 20px;
  }

  .sub-container {
    margin: 0px 24px;
    position: static;
    /* Remove position on mobile */
    left: initial;
    /* Reset left */
    transform: none;
    /* Remove transform */
    min-height: auto;
    /* Reset min-height */
    gap: 16px;
  }

  .right-item {
    margin-top: 109px;
  }
}
</style>
