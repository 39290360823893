<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Blog Page
Description:
This component displays the header of a blog post, including the estimated reading time, categories, main title, subtitle,
and an optional header image. Categories are dynamically formatted as links, allowing users to explore related content.
It creates an inviting and informative introduction for each blog entry.

How to use it:

  :estimateReadingTime="'5 min read'"
  :title="'Blog Post Title'"
  :subTitle="'A short description of the blog post.'"
  :headerImage="{ url: 'path/to/image.jpg', alt: 'Header Image' }"
  :category="categoriesArray"
/>
Pass props such as `estimateReadingTime`, `title`, `subTitle`, `headerImage`, and `category` to provide details for the blog post header.
-->

<template>
  <div class="main-container">
    <section class="header-section">
      <div class="sub-container">
        <div style="display: flex; align-items: center; gap: 8px;">
          <div
            class="category-link"
            v-html="headerMainTitle"
          />
        </div>
        <h1>{{ title }}</h1>
        <h3>{{ subTitle }}</h3>

      </div>
    </section>
    <img
      v-if="headerImage?.url"
      :src="headerImage?.url"
      :alt="headerImage?.alt"
    >
    <img
      v-if="bannerImage?.url"
      :src="bannerImage?.url"
      :alt="bannerImage?.alt"
      class="banner-image"
    >
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Category {
  name: string;
  slug: string;
}

interface LayoutItem {
  acf_fc_layout: string;
  value: string;
}

interface ContentItem {
  acf_fc_layout: string;
  layout: LayoutItem[];
}

const props = defineProps({
  estimateReadingTime: {
    type: String,
    default: '7 min read'
  },
  title: {
    type: String,
    default: '',
  },
  content: {
    type: Array as () => ContentItem[],
    default: () => [],
  },
  subTitle: {
    type: String,
    default: '',
  },
  headerImage: {
    type: Object,
    default: undefined,
  },
  bannerImage: {
    type: Object,
    default: undefined,
  },
  category: {
    type: Array as () => Category[],
    default: undefined,
  },
  postID: { // Define postID here
    type: Number,
    required: true,
  },
});

const headerMainTitle = computed(() => {
  return props.estimateReadingTime + ' • Filed under: ' + formattedCategories.value;
});

// const emit = defineEmits(['readingTimeCalculated', 'updateReadingTime']);

// // Function to emit and update reading time via REST API



// const updateReadingTime = (time: number) => {
//   console.log('Updating Reading Time:', time); // Log here

//   emit('updateReadingTime', time);

//   axios
//     .post('/custom/api/v1/update-reading-time', {
//       post_id: props.postID,
//       reading_time: time,
//     })
//     .then((response) => {
//       console.log('Reading time sent:', time);
//     })
//     .catch((error) => {
//       console.error('Error sending reading time:', error);
//     });
// };

// const estimateReadingTime = computed(() => {
//   // Extract and process content
//   const rawContent = props.content
//     .flatMap((item) => {
//       if (Array.isArray(item.layout)) {
//         return item.layout
//           .filter((subItem) => subItem.value)
//           .map((subItem) => subItem.value);
//       }
//       return [];
//     })
//     .join(' ');

//   if (!rawContent.trim()) {
//     return '0 mins read';
//   }

//   const words = rawContent.trim().split(/\s+/).filter(Boolean).length;
//   const wordsPerMinute = 300;
//   const time = Math.max(Math.ceil(words / wordsPerMinute), 1);

//   // Log the calculated time for debugging
//   console.log('Estimated Reading Time:', time);

//   // Emit the reading time to the parent component
//   emit('readingTimeCalculated', time);
//   console.log('Reading time emitted:', time);

//   // Call updateReadingTime directly here
//   updateReadingTime(time);

//   return `${time} minute${time > 1 ? 's' : ''} read`;
// });




const baseUrl = process.env.APP_URL;

const formattedCategories = computed(() => {
  if (!props.category) return 'Undefined';
  return props.category
    .map(
      (obj) =>
        `<a href="${baseUrl}/blog?category=${obj.slug}" class="category-link">${obj.name ?? 'Undefined'
        }</a>`
    )
    .join(' | ');
});
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 80px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
}

.banner-image {
  width: 100%;
  display: block;
  /* Remove any space below the image */
}

h1 {
  color: #242424;
  font-family: 'Inter' sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  text-align: left;
  padding: 16px 0;
}

h3 {
  color: #969696;
  font-family: 'Inter' sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

p {
  color: #242424;
  font-family: 'Inter' sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
}

.category-link:deep(a) {
  color: #007AFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
}

.main-container img {
  object-fit: cover;
  width: 100%;
  margin: 80px 0;
  aspect-ratio: 12/5;
}

@media screen and (max-width: 820px) {

  .header-section {
    display: flex;
    width: 100%;
    padding: 0;
  }

  .sub-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 80px;
  }

  h1 {
    color: #242424;
    font-family: 'Inter' sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
    text-align: left;
  }

  h3 {
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  p {
    color: #242424;
    font-family: 'Inter' sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .category-link:deep(a) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .main-container img {
    object-fit: cover;
    height: 320px;
    width: 100%;
    margin: 64px 0;
    aspect-ratio: 0;
  }
}

@media screen and (max-width: 480px) {

  .sub-container {
    margin: 0;
    padding: 0 24px;
    /* Adjusted for smaller screens */
  }

  .banner-image {
    width: auto;
    height: 260px;
    object-fit: cover;
    display: block;
    /* Remove any space below the image */
  }
}
</style>
