<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: About Page
Description:
This component provides a compelling narrative and actionable links to guide users through Alphapod's offerings.
It includes a main question that reflects the company's philosophy, detailed supporting text, and multiple link
boxes that navigate to various sections such as "Our Story," "Our Work," "Our Services," and "Join Our Team."
The component also includes a click event to open the "Our Story" section.

How to use it:
<AboutLinkAndDescription
  @open-our-story="showOurStory = true"
/>
-->
<template>
  <div class="content-container">
    <div class="sub-container">
      <div class="text-section">
        <h1>What if we made things that people actually cared about?</h1>
        <div class="details">
          <p>This isn't just a question; it's the driving force behind our entire approach to design and development. Sure, anyone can design a website and build an app, but few bother to challenge preconceptions and ask difficult questions like:</p>
          <ul>
            <li>Do people really need what you’re selling?</li>
            <li>Apart from profitability, how does success look like?</li>
            <li>How do you plan to grow with your users?</li>
          </ul>
          <p>We take the time to think beyond the known parameters—anticipating future needs to ensure that the things we build remain relevant and valuable for years to come so that you can foster long-lasting relationships with your users.</p>
        </div>
      </div>
      <div class="links-section">
        <a
          v-if="mainSite"
          class="link-box"
          style="cursor: pointer;"
          @click="onClickOurStory"
        >
          <div
            class="link-content"
          >
            <h2>Our story</h2>
            <p>Who we are, and why we do what we do.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
        <a
          :href="`${sitePath}${LINKS.WORK}`"
          class="link-box"
        >
          <div class="link-content">
            <h2>Our work</h2>
            <p>Nothing speaks louder.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
        <a
          :href="`${sitePath}${LINKS.SERVICES}`"
          class="link-box"
        >
          <div class="link-content">
            <h2>Our services</h2>
            <p>From idea to launch and back again.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
        <a
          v-if="mainSite"
          :href="`${sitePath}${LINKS.CAREER}`"
          class="link-box"
        >
          <div class="link-content">
            <h2>Join our team</h2>
            <p>Do the best work of your life here.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, inject, computed, ref} from 'vue';
import { LINKS } from '../../support/constant';
import { fadeInEffect } from '../../support/helper';

const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
const sitePath = computed(() => wordpressData?.value?.multisite_path ?? '/');


const emit = defineEmits(['openOurStory']);

const onClickOurStory = () => {
  emit('openOurStory');
};

onMounted(()=>{

  fadeInEffect('.text-section h1');
  fadeInEffect('.text-section .details');
  fadeInEffect('.links-section');
});

</script>

<style scoped>
.content-container {
  color: #242424;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  background-color: #fafafa;
  padding: 160px 0px;
}

.sub-container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  margin: 24px;
}

.text-section h1 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 32px;
}

.text-section ul {
  color: #646464;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  list-style-type: disc;
  margin-bottom: 32px;
  margin-left: 24px;
}

.text-section p {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 32px;
}

.links-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  margin-top: 120px;
}

.link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  flex-grow: 1;
  padding: 16px 24px;
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background color change on hover */
}

.link-box:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

.link-box h2 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
}

.link-box p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.link-content{
  flex-grow: 1;
}
.arrow{
  height: 16px;
  width: 16px;
  color: #242424;
  margin-left: 10px;
}

@media screen and (max-width: 820px) {
  .content-container {
    padding: 80px 0px;
  }

  .sub-container {
    margin: 80px;
  }

  .text-section h1 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
  }

  .text-section p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .text-section ul {
    color: #646464;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .links-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 64px;
  }

  .link-box {
    border-radius: 10px;
  }

  .link-box h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .link-box p {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    margin: 24px;
  }
}
</style>
