<!--
Author: Joachim <joachim@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Home Page

Description:
This component provides a welcoming message and links to the About and Work pages.

How to use it:
<WelcomeMessageAU />
-->
<template>
  <section class="content-container">
    <div class="sub-container">
      <section class="text-section">
        <h1>Welcome to Alphapod Singapore.</h1>
        <h2 class="client-work">
          We work with clients across the globe to deliver digital solutions for a wide variety of industries.
        </h2>
        <br>
        <div class="links-section">
          <div class="link-box-wrapper">
            <a
              href="/sg/about"
              class="link-box"
            >
              <div class="link-content">
                <h2>About us</h2>
                <p>Who we are, and why we do what we do.</p>
              </div>
              <img
                class="arrow"
                :src="$cdn('icons/arrow-right.svg')"
                alt="Arrow right"
              >
            </a>
          </div>
          <div class="border-divider" />

          <div class="link-box-wrapper">
            <a
              href="/sg/work"
              class="link-box"
            >
              <div class="link-content">
                <h2>Our work</h2>
                <p>Nothing speaks louder.</p>
              </div>
              <img
                class="arrow"
                :src="$cdn('icons/arrow-right.svg')"
                alt="Arrow right"
              >
            </a>
          </div>
          <div class="border-divider" />

          <div class="link-box-wrapper">
            <a
              href="/sg/services"
              class="link-box"
            >
              <div class="link-content">
                <h2>Our services</h2>
                <p>From idea to launch and back again.</p>
              </div>
              <img
                class="arrow"
                :src="$cdn('icons/arrow-right.svg')"
                alt="Arrow right"
              >
            </a>
          </div>
          <div class="border-divider" />

          <div class="link-box-wrapper">
            <div
              href="#"
              class="link-box"
              @click="getInTouch"
            >
              <div class="link-content">
                <h2>Get in touch</h2>
                <p>Discover how we can create value together.</p>
              </div>
              <img
                class="arrow"
                :src="$cdn('icons/arrow-right.svg')"
                alt="Arrow right"
              >
            </div>
          </div>
        </div>
      </section>

      <section class="card-section">
        <!-- Left Swiper for odd cards -->
        <div
          v-if="showCardSection"
          class="swiper-container left-swiper"
        >
          <Swiper
            ref="leftSwiper"
            :direction="swiperDirection"
            :loop="true"
            :free-mode="{ enabled: true, momentum: false }"
            :autoplay="{ delay: 0, disableOnInteraction: false }"
            :style="{ height: isSmallScreen ? '' : '1200px', width: isSmallScreen ? '100vw' : '100%' }"
            :slides-per-view="'auto'"
            :breakpoints="{
              0: { spaceBetween: 16, speed: 3500 },
              1024: { spaceBetween: 32, speed: 4500 }
            }"
            :prevent-interaction-on-transition="true"
            @swiper="onSwiperLeft"
          >
            <SwiperSlide
              v-for="(card, index) in duplicatedOddCards"
              :key="`odd-${index}`"
            >
              <div class="">
                <img
                  v-if="card.image"
                  :src="card.image"
                  alt="card image"
                >
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div
          v-if="showCardSection"
          class="swiper-container right-swiper"
        >
          <Swiper
            ref="rightSwiper"
            :direction="swiperDirection"
            :loop="true"
            :free-mode="true"
            :autoplay="{ delay: 0, disableOnInteraction: false, reverseDirection: true }"
            :style="{ height: isSmallScreen ? '' : '1200px', width: isSmallScreen ? '100vw' : '100%' }"
            :slides-per-view="'auto'"
            :breakpoints="{
              0: {
                spaceBetween: 16, // Applies to all screens up to 374px
                speed: 3000, // Faster speed for very small screens
              },
              1024: {
                spaceBetween: 32, // Applies to screens 375px and above
                speed: 4000, // Slower speed for slightly larger screens
              }
            }"
            :prevent-interaction-on-transition="true"
            @swiper="onSwiperRight"
          >
            <SwiperSlide
              v-for="(card, index) in duplicatedEvenCards"
              :key="`even-${index}`"
            >
              <img
                v-if="card.image"
                :src="card.image"
                alt="card image"
              >
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </div>
  </section>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { cdn } from '../../support/cdn';
import { ref, computed, onMounted, onBeforeUnmount, nextTick, inject} from 'vue';
import { Swiper } from 'swiper/vue';
import { Swiper as SwiperClass, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
import SwiperCore, { Swiper as SwiperInstance } from 'swiper';

// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

// Use Swiper modules
SwiperCore.use([Autoplay, FreeMode, Navigation, Pagination]);

// Screen size threshold refs
const isSmallScreen = ref(window.outerWidth < 900);
const swiperDirection = ref<'vertical' | 'horizontal'>(window.outerWidth < 900 ? 'horizontal' : 'vertical');
const showCardSection = ref(false);
const showMenu = ref(false);
const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
const siteID = computed(() => wordpressData?.value?.blog_id ?? 1);
const sitePath = computed(() => wordpressData?.value?.multisite_path ?? '/');
const showForm = ref(false);


const emit = defineEmits(['getInTouch']);


// Card data setup for categorizing odd and even cards, with separate configurations for desktop and mobile

const oddCards = ref([
  { image: cdn('homepage/img_card_ai.png') },
  { image: cdn('homepage/img_card_iot.png') },
  { image: cdn('homepage/img_card_mobile.png') },
  { image: cdn('homepage/img_card_ai.png') },
  { image: cdn('homepage/img_card_iot.png') },
  { image: cdn('homepage/img_card_mobile.png') },
  { image: cdn('homepage/img_card_ai.png') },
  { image: cdn('homepage/img_card_iot.png') },
  { image: cdn('homepage/img_card_mobile.png') },
  { image: cdn('homepage/img_card_ai.png') },
  { image: cdn('homepage/img_card_iot.png') },
  { image: cdn('homepage/img_card_mobile.png') },
  { image: cdn('homepage/img_card_ai.png') },
  { image: cdn('homepage/img_card_iot.png') },
  { image: cdn('homepage/img_card_mobile.png') },
]);

const evenCards = ref([
  { image: cdn('homepage/img_card_vr.png') },
  { image: cdn('homepage/img_card_cloud.png') },
  { image: cdn('homepage/img_card_crm.png') },
  { image: cdn('homepage/img_card_cms.png') },
  { image: cdn('homepage/img_card_vr.png') },
  { image: cdn('homepage/img_card_cloud.png') },
  { image: cdn('homepage/img_card_crm.png') },
  { image: cdn('homepage/img_card_cms.png') },
  { image: cdn('homepage/img_card_vr.png') },
  { image: cdn('homepage/img_card_cloud.png') },
  { image: cdn('homepage/img_card_crm.png') },
  { image: cdn('homepage/img_card_cms.png') },
  { image: cdn('homepage/img_card_vr.png') },
  { image: cdn('homepage/img_card_cloud.png') },
  { image: cdn('homepage/img_card_crm.png') },
  { image: cdn('homepage/img_card_cms.png') },
]);

const oddCardsMobile = ref([
  { image: cdn('homepage/img_card_mobile_ai.png') },
  { image: cdn('homepage/img_card_mobile_iot.png') },
  { image: cdn('homepage/img_card_mobile_mobile.png') },
  { image: cdn('homepage/img_card_mobile_ai.png') },
  { image: cdn('homepage/img_card_mobile_iot.png') },
  { image: cdn('homepage/img_card_mobile_mobile.png') },
  { image: cdn('homepage/img_card_mobile_ai.png') },
  { image: cdn('homepage/img_card_mobile_iot.png') },
  { image: cdn('homepage/img_card_mobile_mobile.png') },
  { image: cdn('homepage/img_card_mobile_ai.png') },
  { image: cdn('homepage/img_card_mobile_iot.png') },
  { image: cdn('homepage/img_card_mobile_mobile.png') },
  { image: cdn('homepage/img_card_mobile_ai.png') },
  { image: cdn('homepage/img_card_mobile_iot.png') },
  { image: cdn('homepage/img_card_mobile_mobile.png') },
]);

const evenCardsMobile = ref([
  { image: cdn('homepage/img_card_mobile_vr.png') },
  { image: cdn('homepage/img_card_mobile_cloud.png') },
  { image: cdn('homepage/img_card_mobile_crm.png') },
  { image: cdn('homepage/img_card_mobile_cms.png') },
  { image: cdn('homepage/img_card_mobile_vr.png') },
  { image: cdn('homepage/img_card_mobile_cloud.png') },
  { image: cdn('homepage/img_card_mobile_crm.png') },
  { image: cdn('homepage/img_card_mobile_cms.png') },
  { image: cdn('homepage/img_card_mobile_vr.png') },
  { image: cdn('homepage/img_card_mobile_cloud.png') },
  { image: cdn('homepage/img_card_mobile_crm.png') },
  { image: cdn('homepage/img_card_mobile_cms.png') },
  { image: cdn('homepage/img_card_mobile_vr.png') },
  { image: cdn('homepage/img_card_mobile_cloud.png') },
  { image: cdn('homepage/img_card_mobile_crm.png') },
  { image: cdn('homepage/img_card_mobile_cms.png') },
]);

// Duplicated versions of odd and even cards
const duplicatedOddCards = computed(() => {
  const oddCardsList = isSmallScreen.value ? oddCardsMobile.value : oddCards.value;
  return [...oddCardsList, ...oddCardsList];
});

const duplicatedEvenCards = computed(() => {
  const evenCardsList = isSmallScreen.value ? evenCardsMobile.value : evenCards.value;
  return [...evenCardsList, ...evenCardsList];
});

// Update screen-related refs on resize
function updateScreenSettings() {
  isSmallScreen.value = window.innerWidth < 900;
  swiperDirection.value = window.innerWidth < 770 ? 'horizontal' : 'vertical';
}

function showContent(path: string): boolean {
  return path.trim() === sitePath.value.trim();
}

// Declaration of specific swiper classes and instances
const leftSwiper = ref<typeof SwiperClass | null>(null);
const rightSwiper = ref<typeof SwiperClass | null>(null);
const swiperInstanceLeft = ref<SwiperInstance | null>(null);
const swiperInstanceRight = ref<SwiperInstance | null>(null);

function onSwiperLeft(swiper: any) {
  swiperInstanceLeft.value = swiper;

  setTimeout(() => {
    swiperInstanceLeft.value?.autoplay.start();
  }, 1000);
}

function onSwiperRight(swiper: any) {
  swiperInstanceRight.value = swiper;

  setTimeout(() => {
    swiperInstanceRight.value?.autoplay.start();
  }, 1000);
  // nextTick(() => {
  //   swiperInstanceLeft.value?.autoplay.start();
  // });
}

const getInTouch = () => {
  showMenu.value = false;
  emit('getInTouch', showForm.value);
  openForm();
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

// Set initial screen settings and register resize listener
onMounted(() => {
  updateScreenSettings(); // Update screen settings on load
  window.addEventListener('resize', updateScreenSettings);

  setTimeout(() => {
    showCardSection.value = true; // Show Swiper sections after a delay

    nextTick(() => {

      // Created single initializeSwiper to be used to initialize both swipers
      const initializeSwiper = (swiperRef: any) => {
        if (swiperRef?.swiper) {
          const swiper = swiperRef.swiper;
          swiper.slideToLoop(0, 0); // Start at the first loop slide
          swiper.update(); // Recalculate layout
          swiper.autoplay.start(); // Ensure autoplay starts
        }
      };

      // Initialize both Swipers
      initializeSwiper(leftSwiper.value);
      initializeSwiper(rightSwiper.value);
    });
  }, 0); // Delay to ensure Swipers are visible
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateScreenSettings);

  if (leftSwiper.value) {
    leftSwiper.value.swiper.autoplay.stop();
  }

  // Stop autoplay for rightSwiper
  if (rightSwiper.value) {
    rightSwiper.value.swiper.autoplay.stop();
  }
});
</script>

<style scoped>
.arrow {
  height: 16px;
  width: auto;
  color: #969696;
  margin-left: 12px;
}

.card-description {
  color: #242424;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.25px;
}

.card-section {
  display: flex; /* Enable flex layout for horizontal scrolling */
  flex-direction: row; /* Horizontal layout */
  gap: 32px; /* Adjust gap between the Swipers as needed */
  width: 100%; /* Full width of the container */
  height: 100%;
  min-width: 240px;
  max-height: 1100px;
}

.card-section::-webkit-scrollbar {
  width: 0; /* Hides vertical scrollbar */
  height: 0; /* Hides horizontal scrollbar (if necessary) */
}

.card-section::-webkit-scrollbar-thumb {
  background: transparent;
}

.card-section::-webkit-scrollbar-track {
  background: transparent;
}

.card.right-column {
  margin-top: 20px;
}

.client-work {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
}

.content-container {
  display: flex;
  background-color: #fafafa;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 120px;
  overflow-x: hidden;
}
.links-section {
  display: block;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  margin-top: 64px;
  height: 500px;
}

.link-box-wrapper {
  padding: 16px;
}

.link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  flex-grow: 1;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  gap: 16px;
}

.link-box:hover {
  background-color: #ededed; /* Hover background color */
  border-radius: 8px;
}

.link-box h2 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
  color: #242424;
}

.link-box p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.link-content {
  flex-grow: 1;
}

/* Spacer / Border Divider */
.border-divider {
  height: 2px;
  background-color: #f0f0f0;
}

/* Remove the border after the last link-box */
.link-box-wrapper:last-of-type + .border-divider {
  display: none;
}

.sub-container {
  max-width: 1280px;
  display: flex;
  align-items: flex-start;
  text-align: start;
  gap: 160px;
}

.swiper-container {
  width: 100%; /* Full width */
  height: 100%; /* Large height for the container */
  max-height: 1090px;
  overflow: hidden; /* Hide the overflow to keep the layout clean */
  justify-content: center; /* Center the swiper inside the container */
}

.swiper {
  max-height: 1090px;
}
.swiper-slide {
  width: 100%; /* Let slides adjust based on content */
  height: fit-content; /* Fixed height for each slide */
  display: flex; /* Flex to center content inside each slide */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 160px 0px;
}

.text-section h1 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
  margin-bottom: 20px;
}
@media screen and (max-width: 1124px) {
  .arrow {
    height: 18px;
    width: auto;
    color: #969696;
    margin-left: 14px;
  }

  .card-description {
    color: #242424;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 133% */
    letter-spacing: -0.3px;
  }

  .card-section {
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 100%;
    height: 100%;
    min-width: 260px;
  }

  .card-section::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .card.right-column {
    margin-top: 24px;
  }

  .client-work {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  .content-container {
    display: flex;
    background-color: #f7f7f7;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 80px;
    max-height: 700px;
  }

  .link-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    flex-grow: 1;
    padding: 6px 8px;
    margin-bottom: 16px;
    border-radius: 12px;
    transition: background-color 0.3s;
  }

  .link-box:hover {
    background-color: #dcdcdc;
  }

  .link-box h2 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    font-style: normal;
    color: #242424;
  }

  .link-box p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    color: #969696;
  }

  .link-content {
    flex-grow: 1;
  }

  .links-section {
    display: block;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
    height: 500px;
    margin-top: 14px;
  }

  .sub-container {
    max-width: 1280px;
    display: flex;
    align-items: flex-start;
    text-align: start;
    gap: 120px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    max-height: 700px;
    overflow: hidden;
    justify-content: center;
  }

  .swiper-slide {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-swiper,
  .right-swiper {
    max-width: 520px;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 72px;
  }

  .text-section h1 {
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 34px;
    font-style: normal;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .arrow {
    height: 16px;
    width: auto;
    color: #969696;
    margin-left: 12px;
  }

  .border-divider {
    height: 2px;
    background-color: #f0f0f0;
    margin: 16px 0px; /* Ensures space around the border */
  }

  .card-description {
    color: #242424;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    letter-spacing: -0.25px;
  }

  .card-section {
    display: flex; /* Enable flex layout for horizontal scrolling */
    flex-direction: row; /* Horizontal layout */
    gap: 16px; /* Adjust gap between the Swipers as needed */
    width: 100%; /* Full width of the container */
    height: 100%;
    min-width: 240px;
  }

  .card-section::-webkit-scrollbar {
    width: 0; /* Hides vertical scrollbar */
    height: 0; /* Hides horizontal scrollbar (if necessary) */
  }

  .card-section::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .card-section::-webkit-scrollbar-track {
    background: transparent;
  }

  .card.right-column {
    margin-top: 20px;
  }

  .client-work {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .content-container {
    display: flex;
    background-color: #fafafa;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    max-height: 700px;
  }

  .link-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    flex-grow: 1;
    padding: 8px 12px;
    margin-bottom: 16px;
    border-radius: 12px;
    transition: background-color 0.3s;
  }

  .link-box:hover {
    background-color: #e0e0e0; /* Hover background color */
  }

  .link-box h2 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    font-style: normal;
    color: #242424;
  }

  .link-box p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #969696;
  }
  .link-box-wrapper {
    padding: 0px 12px 0px 0px;
  }

  .link-content {
    flex-grow: 1;
  }

  .links-section {
    display: block;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;
    height: 400px;
  }

  .sub-container {
    max-width: 1280px;
    display: flex;
    align-items: flex-start;
    text-align: start;
    gap: 100px;
  }

  .swiper-container {
    width: 100%; /* Full width */
    height: 100%; /* Large height for the container */
    max-height: 700px;
    overflow: hidden; /* Hide the overflow to keep the layout clean */
    justify-content: center; /* Center the swiper inside the container */
  }

  .swiper-slide {
    width: 100%; /* Let slides adjust based on content */
    height: fit-content; /* Fixed height for each slide */
    display: flex; /* Flex to center content inside each slide */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  .left-swiper,
  .right-swiper {
    max-width: 500px; /* Ensure max width fits larger screens */
  }

  .text-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 32px;
  }

  .text-section h1 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    font-style: normal;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .border-divider {
    height: 2px;
    background-color: #f0f0f0;
    margin: 16px 0px; /* Ensures space around the border */
  }
  .card-section {
    display: flex;
    flex-direction: column;
    height: 80%; /* Allow height to adjust based on content */
    max-height: 800px; /* Remove the max-height constraint */
    max-width: 778px; /* Retain the desired maximum width */
    width: 100%; /* Ensure it occupies the full width of the parent */
    margin-top: 64px; /* Retain no margin at the top */
    box-sizing: border-box; /* Include padding and border in element's total width/height */
  }

  .client-work {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    width: 100%; /* Adjusted to occupy full width */
  }

  .link-box:hover {
    background-color: initial;
    color: initial;
  }

  .content-container {
    align-items: flex-start;
    background-color: #fafafa;
    color: #242424;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 1600px;
    max-width: 700px; /* Adjusted max width for 744px screen */
    padding: 12px 0;
    text-align: left;
    z-index: 2;
  }

  .link-box {
    max-width: 744px; /* Adjusted size for 744px screen */
    padding: 8px;
    line-height: 20px;
  }

  .link-box h2 {
    font-size: 20px;
  }

  .link-box p {
    font-size: 18px;
  }

  .links-section {
    gap: 24px;
    max-height: 700px; /* Adjusted max-height for 744px screen */
    margin-top: 8px;
    width: 100%;
  }

  .left-swiper,
  .right-swiper {
    max-width: 768px; /* Adjusted max width */
    height: 280px;
  }

  .sub-container {
    align-items: center;
    background-color: #fafafa;
    flex-direction: column;
    gap: 1px;
    width: 100vw;
    height: 2000px;
    max-height: 1400px;
  }

  .swiper-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .swiper-slide {
  max-width: 200px; /* Adjusted max-width */
  width: 100%;

  img {
    width: 100%; /* Set desired width for the image */
  }
}

  .text-section {
    max-height: 800px;
    padding: 80px 120px 0 120px;
  }

  .text-section h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
  }

  .text-section p {
    font-size: 18px;
  }
}
@media screen and (max-width: 540px) {
  .card-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 700px; /* Increased max height */
    max-width: 540px; /* Adjusted max width */
    width: 100%;
    margin-top: 100px;
  }

  .client-work {
    font-size: 28px; /* Slightly larger font size for better readability */
    width: 480px; /* Adjusted width for 540px screen */
  }

  .link-box:hover {
    background-color: initial; /* Reset background color */
    color: initial; /* Reset text color */
  }

  .content-container {
    align-items: flex-start;
    background-color: #fafafa;
    color: #242424;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 1500px; /* Increased max height */
    max-width: 480px; /* Adjusted max width for the larger screen */
    padding: 12px 0px;
    text-align: left;
    z-index: 2;
  }

  .link-box {
    max-width: 540px; /* Adjusted size for 540px screen */
    padding: 16px 4px 4px 4px;
    line-height: 20px;
  }

  .link-box h2 {
    font-size: 20px; /* Increased font size for better readability */
  }

  .link-box p {
    font-size: 18px; /* Adjusted for improved readability */
  }

  .links-section {
    gap: 24px; /* Increased gap for better spacing */
    max-height: 480px; /* Adjusted max-height */
    margin-top: 8px; /* Increased margin */
    width: 100%;
  }

  .left-swiper,
  .right-swiper {
    max-width: 540px; /* Ensured max width fits 540px screens */
  }

  .sub-container {
    align-items: center;
    background-color: #fafafa;
    flex-direction: column;
    gap: 8px; /* Increased gap for spacing */
    width: 100vw;
    height: 1500px;
    max-height: 2000px;
  }

  .swiper-container {
    display: flex;
    flex-direction: column; /* Stack swipers vertically */
    gap: 40px; /* Increased spacing between swipers */
    width: 100%;
  }

  .swiper-slide {
    max-width: 240px; /* Increased max-width for larger screens */
    width: 100%;
  }

  .text-section {
    max-height: 700px; /* Increased max height */
    padding: 80px 20px 0 20px; /* Adjusted padding for more space */
  }

  .text-section h1 {
    font-size: 20px; /* Increased heading font size */
  }

  .text-section p {
    font-size: 18px; /* Slightly larger text for better readability */
  }
}
@media screen and (max-width: 480px) {
  .card-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 550px;
    max-width: 480px;
    width: 100%;
  }

  .client-work {
    font-size: 26px; /* Slightly larger font size */
    width: 400px; /* Adjust width for larger screens */
  }

  .content-container {
    align-items: flex-start;
    background-color: #fafafa;
    color: #242424;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 1190px;
    max-width: 420px; /* Increased max width for larger screen */
    padding: 10px 0px;
    text-align: left;
    z-index: 2;
  }

  .link-box {
    max-width: 480px; /* Adjusted size for 480px screen */
    padding: 16px 4px 4px 4px;
  }

  .link-box h2 {
    font-size: 18px; /* Slightly larger font for better readability */
  }

  .link-box p {
    font-size: 16px; /* Adjusted for readability */
  }

  .links-section {
    gap: 20px; /* Increased gap for spacing */
    max-height: 420px;
    margin-top: 4px; /* Increased margin for better spacing */
    width: 100%;
  }

  .left-swiper,
  .right-swiper {
    max-width: 480px; /* Ensure max width fits larger screens */
  }

  .sub-container {
    align-items: center;
    background-color: #fafafa;
    flex-direction: column;
    gap: 4px;
    width: 100vw;
  }

  .swiper-container {
    display: flex;
    flex-direction: column; /* Stack swipers vertically */
    gap: 32px; /* Add spacing between swipers */
    width: 100%;
  }

  .swiper-slide {
    max-width: 200px; /* Increased max-width for larger screens */
    width: 100%;
  }

  .text-section {
    max-height: 600px;
    padding: 64px 20px 0 20px; /* Top, Right, Bottom, Left */
  }

  .text-section h1 {
    font-size: 18px; /* Increased heading font size */
  }

  .text-section p {
    font-size: 16px; /* Slightly larger text */
  }
}
@media screen and (max-width: 414px) {
  .card-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 600px;
    gap: 16px;
    max-width: 435px; /* Average of 480px and 390px */
    width: 100%;
  }

  .client-work {
    font-size: 24px;
    width: 371px; /* Average of 400px and 342px */
  }

  .content-container {
    align-items: flex-start;
    background-color: #fafafa;
    color: #242424;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 1500px;
    max-width: 397px; /* Average of 420px and 374px */
    padding: 10px 0px;
    text-align: left;
    z-index: 2;
  }
  .link-box:hover {
    background-color: initial; /* Reset background color */
    color: initial; /* Reset text color */
  }

  .link-box {
    max-width: 411px; /* Average of 480px and 342px */
    line-height: 20px;
    padding: 16px 4px 4px 4px;
  }

  .link-box h2 {
    font-size: 16px; /* Average of 18px and 16px */
    line-height: 20px;
  }

  .link-box p {
    font-size: 14px; /* Average of 16px and 14px */
  }

  .links-section {
    gap: 18px; /* Average of 20px and 16px */
    max-height: 420px;
    margin-top: 4px;
    width: 100%;
  }

  .left-swiper,
  .right-swiper {
    max-width: 480px;
  }

  .sub-container {
    align-items: center;
    background-color: #fafafa;
    flex-direction: column;
    gap: 4px;
    width: 100vw;
    height: 1350px;
  }

  .swiper-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-height: 258px;
  }

  .swiper-slide {
    max-width: 190px; /* Average of 200px and 180px */
    width: 100%;
  }

  .text-section {
    max-height: 676px;
    padding: 80px 20px 0 20px; /* Top, Right, Bottom, Left */
  }

  .text-section h1 {
    font-size: 16px; /* Average of 18px and 16px */
  }

  .text-section p {
    font-size: 14px; /* Average of 16px and 14px */
  }
}
@media screen and (max-width: 390px) {
  .card-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .client-work {
    width: 342px;
    font-size: 24px;
  }

  .link-box:hover {
    background-color: initial; /* Reset background color */
    color: initial; /* Reset text color */
  }

  .content-container {
    color: #242424;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background-color: #fafafa;
    z-index: 2;
    height: 100%;
    max-width: 374px;
    max-height: 1500px;
  }

  .link-box {
    max-width: 342px;
  }

  .link-box h2,
  .text-section h1 {
    font-size: 16px;
  }

  .link-box p,
  .text-section p {
    font-size: 14px;
  }

  .links-section {
    gap: 16px;
  }

  .sub-container {
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }

  .text-section {
    margin-top: 32px;
    padding: 0 16px;
  }

  .swiper-slide {
    width: 100%; /* Let slides adjust based on content */
    max-width: 180px;
  }
}
@media screen and (max-width: 374px) {
  .card-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto; /* Adjust height for better fit on smaller screens */
  }

  .client-work {
    width: 320px; /* Slightly smaller width */
    font-size: 22px; /* Reduced font size for compact view */
  }

  .link-box:hover {
    background-color: initial; /* Reset background color */
    color: initial; /* Reset text color */
  }

  .content-container {
    color: #242424;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background-color: #fafafa;
    z-index: 2;
    height: 100%;
    max-width: 320px; /* Smaller max-width */
    max-height: 1400px; /* Slightly reduced max-height */
  }

  .link-box {
    max-width: 320px; /* Match the smaller content width */
  }

  .link-box h2,
  .text-section h1 {
    font-size: 14px; /* Smaller heading size */
  }

  .link-box p,
  .text-section p {
    font-size: 12px; /* Smaller paragraph size */
  }

  .links-section {
    gap: 12px; /* Reduced gap between links */
  }

  .sub-container {
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }

  .text-section {
    margin-top: 24px; /* Reduced top margin */
    padding: 0 12px; /* Smaller padding for narrower screens */
  }

  .swiper-slide {
    width: 100%; /* Let slides adjust based on content */
    max-width: 150px; /* Smaller max-width for slides */
  }
}
</style>

/* 0.5rem 0.875rem 1rem 1.2rem 1.75rem 2rem 3rem 4rem 7.5rem 10rem */
