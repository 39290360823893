<template>
  <div>
    <BlankScreen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      current-section="blogs"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <BlogHeader
      :estimate-reading-time="wordpressData.acf_data.estimate_time_read"
      :title="decodedTitle"
      :content="wordpressData.acf_data.content"
      :sub-title="wordpressData.acf_data.blog_subtitle"
      :banner-image="headerImage"
      :post-i-d="wordpressData.acf_data.the_id"
      :category="wordpressData.blog_tags"
    />
    <BlogContent :content="wordpressData.acf_data.content" />
    <div class="spacing" />
    <ContactUsFooter @open-form="handleFormRequest" />
    <Footer @get-in-touch="showForm = true" />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <PrivacyPolicyBanner />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import BlankScreen from '../components/general/BlankScreen.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Footer from '../components/general/Footer.vue';
import BlogHeader from '../components/blog/BlogHeader.vue';
import PrivacyPolicyBanner from '../components/general/PrivacyPolicyBanner.vue';
import BlogContent from '../components/blog/BlogContent.vue';

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const headerImage = computed(() => {
  if (props.wordpressData) {
    return props.wordpressData.acf_data.header_image;
  }
  return null;
});

// Method to decode HTML entities
// Method to decode HTML entities
const decodeHtmlEntities = (str: string | null) => {
  if (!str) return ''; // Return an empty string if the input is null or undefined
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.documentElement.textContent || doc.body.textContent || ''; // Ensure a string is returned
};

// Decode the title
const decodedTitle = computed(() => decodeHtmlEntities(props.wordpressData?.the_title));

const showForm = ref(false);
const contactInfo = {
  name: '',
  email: '',
  company: ''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 136px;
  }
}
</style>
