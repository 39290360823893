<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: General Components
Description:
This component represents the main header and navigation menu for the website. It includes a responsive design,
showing a full navigation bar on larger screens and a collapsible menu on mobile devices. The header also dynamically
changes its appearance based on the current menu state. The "Get in Touch" button triggers an event for contact us form.

How to use it:
<AppBarDark @getInTouch="handleGetInTouch" />
Include this component at the top of your pages to provide site navigation. The `getInTouch` event can be used
to handle interactions when the "Get in Touch" link is clicked.
-->
<template>
  <div class="main-container">
    <header :class="`header-container ${showMenu ? 'light' : ''}`">
      <div class="sub-container">
        <!-- Logo -->
        <div class="logo">
          <a :href="checkSlash(`${sitePath}`)">
            <img
              :src="$cdn(showMenu ? 'logos/alphapod-logo-light.svg' : 'logos/alphapod-logo-dark.svg')"
              alt="Logo"
              class="header-nav-logo"
            >
          </a>
        </div>

        <!-- Desktop Navigation -->
        <nav
          v-if="!isMobile"
          class="nav-menu"
        >
          <ul>
            <li><a :href="checkSlash(`${sitePath}services/`)">Services</a></li>
            <li><a :href="checkSlash(`${sitePath}work/`)">Work</a></li>
            <li><a :href="checkSlash(`${sitePath}about/`)">About</a></li>
            <li v-if="mainSite">
              <a :href="checkSlash(`${sitePath}blog/`)">Blog</a>
            </li>
            <li v-if="mainSite">
              <a :href="checkSlash(`${sitePath}careers/`)">Careers</a>
            </li>
            <!-- Uncomment if Careers is needed for non-mainSite -->
            <!-- <li v-else><a href="/au/careers/">Careers</a></li> -->
            <li><a
              href="#"
              @click="getInTouch"
            >Get in Touch</a></li>
          </ul>
        </nav>

        <!-- Mobile Navigation Icon -->
        <nav v-else>
          <img
            class="header-nav-icon"
            :src="$cdn(showMenu ? 'icons/icon_close_navbar.svg' : 'icons/button-menu.png')"
            alt="Navigation menu"
            @click="onClickNavIcon"
          >
        </nav>
      </div>
    </header>

    <!-- Mobile Menu -->
    <div
      v-if="showMenu"
      class="mobile-menu-container"
    >
      <div class="menu-list-section">
        <a :href="checkSlash(`${sitePath}services/`)">Services</a>
        <a :href="checkSlash(`${sitePath}work/`)">Work</a>
        <a :href="checkSlash(`${sitePath}about/`)">About</a>
        <a
          v-if="mainSite"
          :href="checkSlash(`${sitePath}blog/`)"
        >Blog</a>
        <a
          v-if="mainSite"
          :href="checkSlash(`${sitePath}careers/`)"
        >Careers</a>
        <!-- Uncomment if Careers is needed for non-mainSite -->
        <!-- <a v-else href="/au/careers/">Careers</a> -->
        <a
          href="#"
          @click="getInTouch"
        >Get in Touch</a>
      </div>
      <footer class="nav-footer">
        <p>Build {{ buildNumber }}</p>
        <p>© 2024 Alphapod Sdn. Bhd. All Rights Reserved</p>
        <p>
          <a :href="checkSlash(`${sitePath}privacy-policy/`)">Privacy Policy</a> |
          <a :href="checkSlash(`${sitePath}cookie-policy/`)">Cookie Policy</a>
        </p>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, onBeforeUnmount, computed, inject } from 'vue';
import { buildNumber } from '../../support/constant';
import axios from 'axios';
import { checkSlash } from '../../support/helper';
const emit = defineEmits(['getInTouch']);

const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
const siteID = computed(() => wordpressData?.value?.blog_id ?? 1);
const sitePath = computed(() => wordpressData?.value?.multisite_path ?? '/');
const isMobile = ref(false);
const showMenu = ref(false);

// Update sitePath to return sitePath value instead of mainSite

const onClickNavIcon = () => {
  if (isMobile.value) {
    showMenu.value = !showMenu.value;
  }
};

watch(showMenu, () => {
  if (showMenu.value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
});

const updateSize = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  updateSize();
  window.addEventListener('resize', updateSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSize);
});

const getInTouch = () => {
  showMenu.value = false;
  emit('getInTouch', {});
};
</script>


<style scoped>
.header-container {
  display: flex;
  position: fixed;
  align-items: center;
  background-color: #00000040;
  justify-content: center;
  max-height: 120px;
  min-height: 68px;
  height: 100%;
  width: 100%;
  max-width: 3840px;
  z-index: 9999;
  transition: background-color 0.3s ease-in-out;
  padding: 0;
}

.sub-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 24px;
}

.header-container.light {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}

.mobile-menu-container {
  display: none;
}

.header-container .logo {
  width: fit-content;
  padding: 10px 0;
}

.nav-menu ul {
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}

.nav-menu ul li {
  display: table-cell;
  position: relative;
  padding: 10px 32px;
}

.nav-menu a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  z-index: 999;

  display: inline-block;
  padding: 4px 0;
  position: relative;
}

.nav-menu a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-menu a:hover:after {
  width: 100%;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .header-container {
    padding: 12px 24px;
    height: fit-content;
  }

  .sub-container {
    margin: 0;
  }

  .mobile-menu-container {
    position: fixed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 100%;
    width: 100%;
    z-index: 999;
  }

  .mobile-menu-container a {
    color: var(--Neutrals-Nero, #242424);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
  }

  .header-nav-logo {
    max-width: 121.5px;
    height: auto;
  }

  .header-nav-icon {
    width: 32px;
    height: 32px;
  }

  .menu-list-section {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-direction: column;
    flex-grow: 1;
  }

  .nav-footer {
    padding-bottom: 24px;
  }

  .nav-footer p,
  .nav-footer a {
    margin: 0 6px;
    align-self: stretch;
    text-align: center;
    color: var(--Neutrals-Nobel, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }
}
</style>
