<template>
  <div class="main-container">
    <div class="title">
      <h1>This is the way</h1>
      <h3>Begin your journey by choosing the track that aligns with your strengths and passions. Whether you’re drawn to creating intuitive, user-centred designs or building innovative digital solutions, each path offers an immersive, hands-on learning experience.</h3>
    </div>
    <!-- Job Cards -->
    <div class="card-section">
      <InternshipCard
        v-for="(job, index) in props.internsJob"
        :id="`id-job-${index}`"
        :key="job.title"
        :job="job"
        :class="{ 'not-hovered-card': hoveredCardIndex !== null && hoveredCardIndex !== index }"
        @apply-intern-job="handleApplyInternJob"
        @on-click-see-more="onClickSeeMore(index)"
        @mouseover="hoveredCardIndex = index"
        @mouseleave="hoveredCardIndex = null"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import InternshipCard from './InternshipCard.vue';
import InternshipJob from '../../model/InternshipJob';
import { ref } from 'vue';

const emit = defineEmits(['applyInternJob']);

const hoveredCardIndex = ref<number | null>(null);


const props = defineProps<{
  internsJob: InternshipJob[];
}>();

// Scroll to the id of the clicked card
const onClickSeeMore = (index: number) => {
  const element = document.getElementById(`id-job-${index}`);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }
};

// Emit the event to apply for the job
const handleApplyInternJob = (job: InternshipJob) => {
  emit('applyInternJob', job);
};

</script>

<style scoped>
.not-hovered-card {
  background-color: #fafafa;
}

@media screen and (max-width: 1024px) {
  .not-hovered-card {
    background-color: #fff;
  }
}

h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  text-align: center;
}

h3 {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 720px;
}

.card-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

@media screen and (max-width: 768px) {
  .main-container {
    gap: 64px;
  }

  h1 {
    font-size: 40px;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .title {
    gap: 24px;
  }

  .card-section {
    gap: 24px;
  }
}
</style>
