<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Works Page

Description:
This component represents the modal gallery for each of the images in the Works page.

How to use it:
<GalleryModal
  :image="image"
  :images="images"
  @close="closeModal"
/>
-->
<template>
  <div class="modal-container">
    <div
      class="modal-gallery"
    >
      <img
        class="prev-button"
        :class="{ 'disabled': currentIndex === 0 }"
        :src="$cdn(prevButtonSrc)"
        alt="prev button"
        @mouseenter="prevButtonSrc = 'icons/icon_prev_gallery_modal_hover.svg'"
        @mouseleave="prevButtonSrc = 'icons/icon_prev_gallery_modal.svg'"
        @click.stop="handleClick('prev')"
      >
      <div class="sub-container">
        <div
          id="image-container"
          ref="imageContainer"
          class="image-container"
        >
          <img
            v-if="image"
            :src="$cdn(image.url)"
            :alt="image.alt"
            @mouseenter="mouseInDragArea = true"
            @mouseleave="mouseInDragArea = false"
          >

        </div>
      </div>
      <img
        class="next-button"
        :class="{ 'disabled': currentIndex === props.images.length - 1 }"
        :src="$cdn(nextButtonSrc)"
        alt="next button"
        @mouseenter="nextButtonSrc = 'icons/icon_next_gallery_modal_hover.svg'"
        @mouseleave="nextButtonSrc = 'icons/icon_next_gallery_modal.svg'"
        @click.stop="handleClick('next')"
      >
      <div class="cool-lightbox-toolbar">
        <img
          v-if="!isPlaying"
          :src="$cdn('icons/icon_gallery_play.svg')"
          alt="play button"
          @click="playGallery"
        >
        <img
          v-else
          :src="$cdn('icons/icon_gallery_pause.svg')"
          alt="pause button"
          @click="playGallery"
        >
        <img
          :src="$cdn('icons/icon_gallery_thumbnail.svg')"
          alt="thumbnail button"
          @click="toggleThumbnail"
        >
        <img
          :src="$cdn('icons/icon_gallery_close.svg')"
          alt="close button"
          @click="closeModalOnClick"
        >
      </div>
      <span v-if="showHint">Use keyboard arrow keys to navigate</span>
    </div>

    <div
      v-show="showSummary"
      :class="{ 'summary-gallery': true, 'active': showSummary }"
    >
      <img
        v-for="(obj, index) in props.images"
        :key="index"
        :class="currentIndex === index ? 'active' : ''"
        :src="$cdn(obj.url)"
        :alt="obj.alt"
        @click="currentIndex = index"
      >
    </div>

  </div>
</template>

<script setup lang="ts">
// Imports
import { computed, ref, watch, nextTick, onMounted, onUnmounted } from 'vue';
import { gsap, Draggable, Observer } from 'gsap/all';

// Register GSAP plugins
gsap.registerPlugin(Draggable);
gsap.registerPlugin(Observer);

// Props definition
interface Image {
  url: string;
  alt: string;
}
const props = defineProps({
  images: {
    type: Array as () => Image[],
    default: () => [
      { url: '/project/placeholder.svg', alt: 'Placeholder' },
      { url: '/story/img_our_story_mobile-2.jpg', alt: 'Placeholder' }
    ]
  },
  startAt:{
    type: Number,
    default: 0
  }
});

// Emit function for emitting events
const emit = defineEmits(['close-modal']);

// Reactive state
const currentIndex = ref(0);
const imageContainer = ref(null);
const prevButtonSrc = ref('icons/icon_prev_gallery_modal.svg');
const nextButtonSrc = ref('icons/icon_next_gallery_modal.svg');
const showHint = ref(false);
const showSummary = ref(false);
const isPlaying = ref(false);
const intervalId = ref();

const mouseInDragArea = ref(false);

// Computed properties
const image = computed(() => props.images[currentIndex.value]);
const isMobile = computed(() => window.innerWidth <= 768);

// Functions
function changeImage() {
  nextTick().then(() => {
    if (imageContainer.value) {
      const imgElement = imageContainer.value.querySelector('img');
      imgElement.onload = () => {
        const maxWidth = isMobile.value ? window.innerWidth : 66.7 * window.innerWidth / 100; // 66.7vw
        const maxHeight = (isMobile.value ? 95 : 90) * window.innerHeight / 100; // 90vh
        const aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
        let targetWidth, targetHeight;
        if (isMobile.value) {
          targetWidth = Math.min(imgElement.naturalWidth, maxWidth);
          targetHeight = targetWidth / aspectRatio;

        }else{
          if (aspectRatio > 1) { // Image is wider
            targetWidth = Math.min(imgElement.naturalWidth, maxWidth);
            targetHeight = targetWidth / aspectRatio;
          } else { // Image is taller
            targetHeight = Math.min(imgElement.naturalHeight, maxHeight);
            targetWidth = targetHeight * aspectRatio;
          }
        }
        gsap.to(imageContainer.value, {
          width: targetWidth,
          height: targetHeight,
          duration: 0.5,
          ease: 'power2.inOut',
          onComplete: () => {
            gsap.to(imgElement, { opacity: 1, duration: 0.5 });
          }
        });
        imgElement.style.opacity = 0;
      };
    }
  });
}

function handleSwipe(direction: any) {
  if (direction === 'left' && currentIndex.value < props.images.length - 1) {
    currentIndex.value++;
  } else if (direction === 'right' && currentIndex.value > 0) {
    currentIndex.value--;
  }
  gsap.set(imageContainer.value, {x: 0, duration: 0.5, ease: 'elastic.inOut'});
}

function handleClick(direction: any) {
  if (direction === 'next' && currentIndex.value < props.images.length - 1) {
    currentIndex.value++;
    resetTimer();
  } else if (direction === 'prev' && currentIndex.value > 0) {
    currentIndex.value--;
    resetTimer();
  }
}

function playGallery(){
  isPlaying.value = !isPlaying.value;
  if (isPlaying.value) {
    resetTimer();
  } else {
    // Clear the interval if isPlaying is false
    clearInterval(intervalId.value);
  }
}

function resetTimer() {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
  if (isPlaying.value) {
    intervalId.value = setInterval(() => {
      currentIndex.value = (currentIndex.value + 1) % props.images.length;
    }, 5000);
  }
}

function toggleThumbnail(){
  showSummary.value = !showSummary.value;
}

function closeModalOnClick() {
  emit('close-modal');
}

function handleArrowKeys(event: any) {
  switch(event.key) {
    case 'ArrowLeft':
      // Move to the previous image
      handleClick('prev');
      break;
    case 'ArrowRight':
      showHint.value = false;
      // Move to the next image
      handleClick('next');
      break;
    case 'ArrowUp':
      // Move to the previous image
      handleClick('prev');
      break;
    case 'ArrowDown':
      showHint.value = false;
      // Move to the next image
      handleClick('next');
      break;
    case 'Escape':
      // Close the modal
      emit('close-modal');
  }
}

// Watchers
watch(currentIndex, (_) => {
  changeImage();
});

// Lifecycle Hooks
onMounted(() => {
  if (props.startAt > 0){
    currentIndex.value = props.startAt;
  }
  // this drag functionality cause a bug on mobile view, so it's disabled
  let container = imageContainer.value; // Reference to the draggable container
  // let container = document.getElementById('image-container');
  let direction = ref('');
  if (container) {
    Draggable.create(container, {
      type: 'x',
      edgeResistance: 0.75,
      bounds: container.parentNode,
      inertia: true,
      onMove: function() {
        if (this.x < -20){
          direction.value = 'left';
        }else if(this.x > 20){
          direction.value = 'right';
        }else{
          direction.value = '';
        }
      },
      onDragEnd: function() {
        if (isMobile.value) {
          if (direction.value === 'left') {
            handleClick('next');
          }else{
            handleClick('prev');
          }
          direction.value = '';
        }else{
          let direction = this.getDirection('start');
          handleSwipe(direction);
        }
      }
    });
  }
  if (props.images.length > 1) {
    showHint.value = true;
  }
  window.addEventListener('keydown', handleArrowKeys);
  changeImage(); // Initial image setup
});

onUnmounted(() => {
  window.removeEventListener('keydown', handleArrowKeys);
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
});

</script>


<style scoped>

.modal-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
}

.modal-gallery {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sub-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.image-container {
  max-width: 66.7vw;
  max-height: 90vh;
  width: auto;
  display: block;
  background-color: white;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.prev-button, .next-button {
  cursor: pointer;
  padding: 6px;
  z-index: 10;
  opacity: 1;
}

.prev-button.disabled, .next-button.disabled {
  opacity: 0;
}

.modal-gallery span{
  color: white;
  position: absolute;
  bottom: 0;
}

.cool-lightbox-toolbar{
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  display: flex;
}

.cool-lightbox-toolbar img {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px 16px;
  z-index: 9999;
}

.summary-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 10px;
  max-width: 20vw;
  padding: 10px;
  transition: transform 0.3s ease-out; /* Animation duration and easing function */
  transform: translateX(100%);
}

.summary-gallery.active {
  transform: translateX(0); /* Move into view */
}

.summary-gallery img {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
}

.summary-gallery img.active {
  border: 2px solid blue;
}


@media screen and (max-width: 768px) {

  .modal-container{
    flex-direction: column;
  }

  .image-container {
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    z-index: 10;
  }

  .prev-button {
    position: fixed;
    left: 0;
    z-index: 9999;
  }

  .next-button {
    position: fixed;
    right: 0;
    z-index: 9999;
  }

  .modal-gallery span{
    display: none;
  }

  .summary-gallery {
    display: flex;
    gap: 10px;
    max-width: 100%;
    max-height: 10vh;
    padding-right: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;  /* For Firefox */
  }

  .summary-gallery::-webkit-scrollbar {
    display: none;  /* For Chrome, Safari, and Opera */
  }


  .summary-gallery img {
    height: 100%;
    width: auto;
  }
}

</style>
