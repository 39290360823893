<template>
  <div>
    <BlankScreen v-show="showForm" />
    <AppBarLight
      current-section="work"
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <div
      v-for="(data, index) in wordpressData.acf_data.body"
      :key="index"
    >
      <div v-if="data.acf_fc_layout === 'header'">
        <ProjectHeader :data="data" />
      </div>
      <div v-else-if="data.acf_fc_layout === 'description'">
        <ProjectDetails :data="data" />
      </div>
      <div v-else-if="data.acf_fc_layout === 'what_we_did'">
        <ProjectDescription
          :data="data"
          :categories="wordpressData.the_categories"
          :tags="wordpressData.the_tags"
          @show-all-orgs="fetchWorksData"
        />
      </div>
      <div v-else-if="data.acf_fc_layout === 'gallery'">
        <ProjectGallery :data="data" />
      </div>
      <div v-else-if="data.acf_fc_layout === 'challenge_and_solution'">
        <ProjectChallenge :data="data" />
      </div>
      <div v-else-if="data.acf_fc_layout === 'how_we_involved'">
        <ProjectInvolvement
          :data="data"
          @get-in-touch="showForm = true"
        />
      </div>
      <div v-else-if="data.acf_fc_layout === 'testimonial'">
        <Testimonial :data="data" />
      </div>
      <div v-else-if="data.acf_fc_layout === 'results'">
        <ProjectResult :data="data" />
      </div>
      <div v-else-if="data.acf_fc_layout === 'single_paragraph'">
        <ProjectConclusion :data="data" />
      </div>
    </div>
    <ContactUsFooter
      :id="wordpressData.the_id"
      @open-form="handleFormRequest"
    />
    <Footer
      @get-in-touch="openForm"
    />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <transition name="fade">
      <ProjectOrganisationShowcase
        v-if="showAllOrganisations"
        :data="workDatas"
        @close="showAllOrganisations = false"
      />
    </transition>
    <PrivacyPolicyBanner />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import axios from 'axios';
import AppBarLight from '../components/general/AppBarLight.vue';
import BlankScreen from '../components/general/BlankScreen.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Testimonial from '../components/general/Testimonial.vue';
import Footer from '../components/general/Footer.vue';
import ProjectHeader from '../components/project/ProjectHeader.vue';
import ProjectDetails from '../components/project/ProjectDetails.vue';
import ProjectDescription from '../components/project/ProjectDescription.vue';
import ProjectGallery from '../components/project/ProjectGallery.vue';
import ProjectChallenge from '../components/project/ProjectChallenge.vue';
import ProjectInvolvement from '../components/project/ProjectInvolvement.vue';
import ProjectResult from '../components/project/ProjectResult.vue';
import ProjectConclusion from '../components/project/ProjectConclusion.vue';
import ProjectOrganisationShowcase from '../components/project/ProjectOrganisationShowcase.vue';
import PrivacyPolicyBanner from '../components/general/PrivacyPolicyBanner.vue';

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const showForm = ref(false);
const showAllOrganisations = ref(false);
const mainSite = computed(() => props.wordpressData?.is_main_site ?? true);
const siteID = computed(() => {
  // If mainSite is false, return 2, otherwise use site_id from wordpressData (or default to 1)
  return props.wordpressData?.is_main_site === false ? 2 : props.wordpressData?.site_id ?? 1;
});
const workDatas = ref([

]);

const contactInfo = {
  name:'',
  email:'',
  company:''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};
const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

const fetchWorksData = async () => {
  if (workDatas.value.length === 0){
    try {
      const baseUrl = process.env.APP_URL;
      const response = await axios.get(baseUrl + '/wp-json/api/v1/works');
      workDatas.value = response.data;
    } catch (error) {
      console.error('Error fetching works data:', error);
      throw error;
    }
  }
  showAllOrganisations.value = true;
};

watch(showAllOrganisations, () => {
  if (showAllOrganisations.value) {
    openModal();
  } else {
    closeModal();
  }
});

let scrollPosition = 0;
function openModal() {
  scrollPosition = window.pageYOffset;  // Save the scroll position
  document.body.style.overflow = 'hidden';
  // document.body.style.position = 'fixed';  // Optional: for better handling on iOS devices
  document.body.style.top = `-${scrollPosition}px`;
}

function closeModal() {
  document.body.style.overflow = 'auto';
  // document.body.style.position = '';
  window.scrollTo(0, scrollPosition);  // Restore the scroll position
}


</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}
.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 180px;
  }
}

</style>
