<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component provides a dropdown menu for users to select their country code and phone number.
The component includes an input field, a dropdown button, and a dropdown menu that displays country codes.
The component includes a click event to open the dropdown menu.

How to use it:
<PhoneNumberDropdown
  :country-code="countryCode"
  :phone-number="phoneNumber"
  @update:country-code="updateCountryCode"
  @update:phone-number="updatePhoneNumber"
/>
-->
<template>
  <div
    ref="mainContainerRef"
    style="display: flex; flex-direction: column;"
  >
    <div class="phone-number-container">
      <!-- country code -->
      <Dropdown
        ref="dropdownManual"
        :manual="true"
        :align="isMobile ? 'right' : 'left'"
        @visible-change="onVisibleChange"
      >
        <!-- dropdown trigger -->
        <template
          #trigger
        >
          <input
            id="country-code"
            ref="countryCodeInputRef"
            v-model="countryCodeRef"
            :style="[caretImage, inputStyle]"
            :placeholder="tempCountryCode"
            autocomplete="new-password"
            autocorrect="off"
            spellcheck="false"
            @click="onTapCountryCodeDropdown"
            @focus="onTapCountryCodeDropdown"
            @focusout="onFocusoutCountryCodeDropdown"
            @input="onInputCountryCode"
          >
        </template>
        <div
          class="country-code-dropdown-content"
          :style="dropdownStyle"
        >
          <div
            v-for="(option, index) in filteredCountryCodeOptions"
            :key="option.code"
          >
            <button
              type="button"
              class="country-code-dropdown-item"
              :class="dropdownSelectIndex === index ? 'active' : ''"
              @click="onSelectCountryCode(option.dial_code)"
            >
              <div style="display: flex; justify-content: space-between;">
                <span>{{ option.name }}</span>
                <span>{{ option.dial_code }}</span>
              </div>
            </button>
          </div>
        </div>
      </Dropdown>
      <!-- number -->
      <input
        id="role"
        v-model="phoneNumberRef"
        type="text"
        placeholder="1234567890"
        @input="onPhoneNumberChange"
      >
    </div>
    <span v-if="!validPhoneNumber">Invalid phone number.</span>
  </div>
</template>

<script setup lang="ts">
import Dropdown from 'v-dropdown';
// import CountryCodeJSON from '../assets/country-dial-codes.json';
import { ref, computed, onMounted, watch } from 'vue';
import { cdn } from '../../support/cdn';
import { gsap } from 'gsap';
import axios from 'axios';

const dropdownManual = ref(null);
const dropdownSelectIndex = ref(0);
const tempCountryCode = ref('+60');

const mainContainerRef = ref(null);

const phoneNumberRef = ref('');
const countryCodeRef = ref('+60');
const countryCodeInputRef = ref(null);

const CountryCodeJSON = ref<CountryCode[]>([]);


const emit = defineEmits(['phoneNumberValid', 'updatePhoneNumber', 'updateCountryCode']);

interface CountryCode {
  name: string;
  dial_code: string;
  code: string;
}

const props = defineProps({
  formContainer: {
    type: Object,
    default: undefined
  },
  countryCode: {
    type: String,
    required: true
  },
  phoneNumber: {
    type: String,
    required: true
  }
});

// Computed value for filtering country codes
const filteredCountryCodeOptions = computed(() => {
  if (dropdownManual.value) {
    (dropdownManual.value as any).adjust();
  }
  dropdownSelectIndex.value = 0;
  if (countryCodeRef.value.length === 0) {
    return CountryCodeJSON.value;
  }
  return CountryCodeJSON.value.filter((option: CountryCode) => {
    return option.name.toLowerCase().includes(countryCodeRef.value.toLowerCase()) ||
      option.dial_code.toLowerCase().includes(countryCodeRef.value.toLowerCase());
  });
});

const isMobile = computed(() => {
  return window.innerWidth <= 832;
});

const dropdownStyle = ref({
  width: '100%',
});

// Function to update the phone number input to only allow numeric values
function onPhoneNumberChange(event: any) {
  const inputElement = event.target;
  let inputValue = inputElement.value;

  // Extract only numeric characters
  const numericValue = inputValue.replace(/\D/g, '');
  inputElement.value = numericValue;

  // Set cursor to the end of the input value
  if (typeof inputElement.selectionStart === 'number') {
    inputElement.selectionStart = inputElement.selectionEnd = numericValue.length;
    emit('updatePhoneNumber', numericValue);
  } else {
    inputElement.setSelectionRange(numericValue.length, numericValue.length);
  }
}

function onInputCountryCode(_: any) {
  if (dropdownManual.value){
    (dropdownManual.value as any).display();
    setTimeout(() => {
      (dropdownManual.value as any).adjust();
    },100);
  }
}

// Computed value to validate the phone number
const validPhoneNumber = computed(() => {
  if (phoneNumberRef.value.length > 0) {
    let minimumPhoneNumberLength = 8;
    if (countryCodeRef.value !== '+60') {
      minimumPhoneNumberLength = 6;
    }
    const phoneNumberRegex = new RegExp(`^\\d{${minimumPhoneNumberLength},}$`);
    return phoneNumberRegex.test(phoneNumberRef.value.trim());
  }
  return true;
});

// Function to display the country code dropdown
function onTapCountryCodeDropdown() {
  if (dropdownManual.value) {
    countryCodeRef.value = '';
    if (isMobile.value) {
      if (props.formContainer){
        gsap.to(props.formContainer, {
          scrollTop: (mainContainerRef.value as any).offsetTop,
          duration: 0.2,
        });
        setTimeout(() => {
          (dropdownManual.value as any).display();
          setTimeout(() => {
            (dropdownManual.value as any).adjust();
          },200);
        },300);
      }
    }else{
      (dropdownManual.value as any).display();
      setTimeout(() => {
        (dropdownManual.value as any).adjust();
      },200);
    }
  }
}

// Function to select a country code from the dropdown
function onSelectCountryCode(dialCode: string) {
  if (dropdownManual.value) {
    if (dialCode.length > 0) {
      countryCodeRef.value = dialCode;
      tempCountryCode.value = dialCode;
      setTimeout(() => {
        (dropdownManual.value as any).close();
      },200);
    }
  }
}

// Function to handle focusout event of the country code dropdown
function onFocusoutCountryCodeDropdown(event: FocusEvent) {
  window.removeEventListener('keydown', handleKeyEvent);
  setTimeout(() => {
    countryCodeRef.value = tempCountryCode.value;
    if (dropdownManual.value) {
      (dropdownManual.value as any).close();
    }
  }, 200);
}

// Function to scroll to the active item in the dropdown
const scrollToActiveItem = (key: any) => {
  const dropdown = document.querySelector('.country-code-dropdown-content');
  const activeItem = document.querySelector('.country-code-dropdown-item.active');

  if (dropdown && activeItem) {
    const dropdownRect = dropdown.getBoundingClientRect();
    const activeRect = activeItem.getBoundingClientRect();

    const difference = activeRect.top - dropdownRect.top;
    if (difference > 128 && key === 'ArrowDown') {
      dropdown.scrollTop += 40;
    }else if (difference < 40 && key === 'ArrowUp') {
      dropdown.scrollTop -= 40;
    }
  }
};
// Function to handle keyboard events for the country code dropdown
function handleKeyEvent(event: KeyboardEvent) {
  switch (event.key) {
    case 'ArrowDown':
      if (dropdownSelectIndex.value === filteredCountryCodeOptions.value.length - 1) {
        return;
      }
      dropdownSelectIndex.value = (dropdownSelectIndex.value + 1) % filteredCountryCodeOptions.value.length;
      scrollToActiveItem(event.key);
      break;
    case 'ArrowUp':
      if (dropdownSelectIndex.value === 0) {
        return;
      }
      dropdownSelectIndex.value = (dropdownSelectIndex.value - 1 + filteredCountryCodeOptions.value.length) % filteredCountryCodeOptions.value.length;
      scrollToActiveItem(event.key);
      break;
    case 'Enter':
      event.preventDefault();
      onSelectCountryCode(filteredCountryCodeOptions.value[dropdownSelectIndex.value].dial_code);
      break;
  }
}

function onVisibleChange(visible: boolean) {
  if (visible) {
    if (props.formContainer){
      props.formContainer.addEventListener('scroll', handleScroll);
      window.addEventListener('keydown', handleKeyEvent);
    }
  } else {
    if (props.formContainer){
      props.formContainer.removeEventListener('scroll', handleScroll);
      window.removeEventListener('keydown', handleKeyEvent);
    }
  }
}

// Background image for the dropdown caret
const caretImage = ref({
  backgroundImage: `url(${cdn('icons/icon_caret_down.svg')})`,
});

const inputStyle = {
  paddingRight: '45px' // Adjust the padding as needed
};

function handleScroll() {
  if (countryCodeInputRef.value) {
    (countryCodeInputRef.value as any).blur();
  }
}

onMounted(() => {
  watch(validPhoneNumber, () => {
    emit('phoneNumberValid', validPhoneNumber.value);
  });

  watch(countryCodeRef, () => {
    emit('updateCountryCode', countryCodeRef.value);
  });

  watch(phoneNumberRef, () => {
    emit('updatePhoneNumber', phoneNumberRef.value);
  });

  // Attempt to get country codes from sessionStorage
  const storedCountryCodes = sessionStorage.getItem('countryCodes');

  if (storedCountryCodes) {
  // If country codes are found in sessionStorage, parse and use them
    CountryCodeJSON.value = JSON.parse(storedCountryCodes);
  } else {
  // If country codes are not found, fetch them from the server
    axios.get(cdn('json/country-dial-codes.json'))
      .then((response) => {
      // Save the fetched data in sessionStorage
        const countryCodes = response.data;
        sessionStorage.setItem('countryCodes', JSON.stringify(countryCodes));

        // Also set the data to your reactive variable
        CountryCodeJSON.value = countryCodes;
      })
      .catch((error) => {
        console.error('Error fetching country codes:', error);
        CountryCodeJSON.value = [
          {
            'name': 'Malaysia',
            'dial_code': '+60',
            'code': 'MY'
          },
          {
            'name': 'Singapore',
            'dial_code': '+65',
            'code': 'SG'
          },
          {
            'name': 'Australia',
            'dial_code': '+61',
            'code': 'AU'
          }
        ];
      });
  }


  phoneNumberRef.value = props.phoneNumber;
  countryCodeRef.value = props.countryCode;
  dropdownStyle.value = {
    width: (mainContainerRef.value as any).offsetWidth + 'px',
  };
});
</script>

<style scoped>
.phone-number-container{
  display: flex;
  gap: 16px;
}

.phone-number-container input{
  background-repeat: no-repeat;
  background-position: right 24px center;
  padding-right: 48px;
}

.form-group input,
.form-group select {
  width: calc(100% - 6px);
  margin: 0 3px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
  outline: none;
  background-color: #FFF;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 24px;
}

.form-group input:focus, .form-group select:focus {
  border: 1px solid var(--Neutrals-Gainsboro, transparent);;
  outline: 2px solid var(--Neutrals-Gainsboro, #007aff);
}

.form-group span {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.country-code-dropdown-content{
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.country-code-dropdown-item{
  width: 100%;
  height: auto;
  padding: 4px 8px;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  line-height: 32px;
}

.country-code-dropdown-item.active, .country-code-dropdown-item:hover {
  background-color: #007AFF;
  color: #FFF;
  border-radius: 6px;
}

.form-group span {
  color: #DC4664;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 12px;
}


@media screen and (max-width: 820px) {
  .form-group input,
  .form-group select {
    width: calc(100% - 6px);
    margin: 0 3px;
    height: 48px;
    padding: 12px 24px;
    background-color: #fff;
    border: 1px solid var(--Neutrals-Gainsboro, #DCDCDC);
    border-radius: 10px;
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    background-size: 12px 12px;
  }

  .country-code-dropdown-item {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .phone-number-container{
    display: flex;
    gap: 12px;
  }

  .country-code-dropdown-content{
    overflow-y: auto;
  }

  .form-group span {
    font-size: 14px;
    line-height: 28px;
  }

  .form-group input{
    padding: 12px 24px;
  }
}
</style>
