<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Blog Page
Description:
This component is responsible for rendering the dynamic content of a blog post. It supports multiple content types,
including text, headers (H1-H4), paragraphs, bullet lists, numbered lists, images, and galleries. The content is
rendered based on the layout configuration provided, ensuring the correct presentation of each element.
This component also uses a BlogSlider component to display image galleries interactively.

How to use it:
<BlogContent :content="contentArray" />
Pass a `content` prop containing an array of content objects. Each object should define the `acf_fc_layout`
to determine the content type, such as 'text', 'image', or 'gallery'.
-->
<template>
  <div class="main-container">
    <div
      v-if="content"
      class="sub-container"
    >
      <div
        v-for="(obj, index) in content"
        :key="index"
        class="content"
      >
        <div
          v-if="obj.acf_fc_layout === 'text'"
          class="content-text"
        >
          <template
            v-for="(item, i) in obj.layout"
            :key="`text-${i}`"
          >
            <h1
              v-if="item.acf_fc_layout === 'h1'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <h2
              v-else-if="item.acf_fc_layout === 'h2'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <h3
              v-else-if="item.acf_fc_layout === 'h3'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <h4
              v-else-if="item.acf_fc_layout === 'h4'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <div
              v-else-if="item.acf_fc_layout === 'p'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <div
              v-else-if="item.acf_fc_layout === 'editor'"
              class="text-editor"
              v-html="parser(item.value)"
            />
            <b
              v-else-if="item.acf_fc_layout === 'b'"
              :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
              v-html="item.value"
            />
            <ul
              v-else-if="item.acf_fc_layout === 'bullet_list'"
              :style="{ 'padding-bottom': (isMobile ? item.bottom_padding_mobile : item.bottom_padding) + 'px' }"
            >
              <li
                v-for="(li, j) in item.lists"
                :key="j"
                :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
                v-html="li.value"
              />
            </ul>
            <ol
              v-else-if="item.acf_fc_layout === 'number_list'"
              :style="{ 'padding-bottom': (isMobile ? item.bottom_padding_mobile : item.bottom_padding) + 'px' }"
            >
              <li
                v-for="(li, j) in item.lists"
                :key="j"
                :style="{ 'padding-bottom': (isMobile ? item.pad_mobile : item.pad) + 'px' }"
                v-html="li.value"
              />
            </ol>
          </template>
        </div>
        <div
          v-if="obj.acf_fc_layout === 'image'"
          class="content-image"
        >
          <img
            :src="obj.value.url"
            :alt="obj.value?.alt || 'This is an image'"
          >
          <p v-if="obj.value.caption">
            {{ obj.value.caption }}
          </p>
        </div>
        <div
          v-if="obj.value && obj.acf_fc_layout === 'video'"
          class="content-video"
        >
          <!-- If the URL is a direct video file (mp4, webm, etc.), use <video> -->
          <video
            v-if="isDirectVideo(obj.value.url)"
            :src="obj.value.url"
            autoplay
            muted
            playsinline
            controls
            loop
          />

          <!-- If the URL is an embeddable video (YouTube/Vimeo), use <iframe> -->
          <iframe
            v-else
            :src="getAutoplayUrl(obj.value.url, true)"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          />
          <p v-if="obj.value.caption">
            {{ obj.value.caption }}
          </p>
        </div>

        <div
          v-if="obj.acf_fc_layout === 'gallery'"
          class="content-gallery"
        >
          <BlogSlider
            v-if="obj.layout === 'slider'"
            :datas="obj.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';
import BlogSlider from './BlogSlider.vue';

interface ContentObject {
  acf_fc_layout: string;
  value: any;
  layout: any[] | string;
}

const props = defineProps({
  content: {
    type: Array as () => ContentObject[],
    default: undefined
  }
});

function parser(text: string): string {
  return text.replace(/<p>&nbsp;<\/p>/g, '<p style="line-height: 8px;">&nbsp;</p>');
}


const isMobile = computed(() => {
  return window.innerWidth <= 820;
});

const isDirectVideo = (url: string) => {
  return /\.(mp4|webm|ogg)$/i.test(url); // Checks if URL ends with .mp4, .webm, or .ogg
};

const getAutoplayUrl = (url: string, loop = false) => {
  if (!url) return '';

  try {
    const parsedUrl = new URL(url);
    let videoId = '';

    // Handle YouTube links (both /watch?v= and /embed/)
    if (parsedUrl.hostname.includes('youtube.com') || parsedUrl.hostname.includes('youtu.be')) {
      parsedUrl.searchParams.set('autoplay', '1');
      parsedUrl.searchParams.set('mute', '1'); // Mute is required for autoplay

      if (parsedUrl.pathname.includes('/embed/')) {
        videoId = parsedUrl.pathname.split('/embed/')[1]?.split('?')[0] || '';
      } else {
        videoId = parsedUrl.searchParams.get('v') || '';
      }

      if (loop && videoId) {
        parsedUrl.searchParams.set('loop', '1');
        parsedUrl.searchParams.set('playlist', videoId); // Required for YouTube loop
      }
    }

    // Handle Vimeo links
    if (parsedUrl.hostname.includes('vimeo.com')) {
      parsedUrl.searchParams.set('autoplay', '1');
      parsedUrl.searchParams.set('muted', '1');

      if (loop) {
        parsedUrl.searchParams.set('loop', '1');
      }
    }

    return parsedUrl.toString();
  } catch (e) {
    console.error('Invalid video URL:', url);
    return url; // Fallback to original URL if parsing fails
  }
};


</script>

<style scoped>
@import './style.scss';

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px;
}

.sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content-image {
  width: 100%;
  height: auto;
  margin: 120px 0;
  max-width: 1120px;
}

.content-image p {
  color: #646464;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.25px;
  padding-top: 32px;
}

.content-gallery {
  width: 100vw;
  height: auto;
}

.content-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #242424;
  font-family: 'Inter' sans-serif;
  text-align: left;
  max-width: 1120px;
  padding: 0;
}

.content-text h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
}

.content-text h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
}

.content-text h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
}

.content-text h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.content-text:deep(p) {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content-text:deep(a) {
  color: #007AFF;
  text-decoration: underline;
}

.content-text b {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content-text ul {
  list-style-type: disc;
  padding-left: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content-text ol {
  list-style-type: decimal;
  padding-left: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.25px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content-video {
  width: 100%;
  max-width: 1120px;
  display: block;
  margin-top: 120px;
  margin-bottom: 120px;
}

.content-video iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.content-video p {
  color: #646464;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
  padding-top: 32px;
}

@media screen and (max-width: 820px) {

  .main-container {
    padding: 0;
  }

  .sub-container {
    margin: 0;
    padding: 0 80px;
  }

  .content-image {
    margin: 48px 0;
  }

  .content-video {
    margin: 48px 0;
  }

  .content-image p {
    padding-top: 16px;
    font-size: 14px;
    line-height: 24px;
  }

  .content-video p {
    padding-top: 16px;
    font-size: 14px;
    line-height: 24px;
  }

  .content-text {
    padding: 0;
  }

  .content-text h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -3px;
  }

  .content-text h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
  }

  .content-text h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .content-text h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }

  .content-text:deep(p) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .content-text b {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .content-text ul {
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .content-text ol {
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    margin: 0;
    padding: 0 24px;
  }
}
</style>
