<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: General Components

Description:
This component provides a placeholder to display showcase footer content, with error handling and loading states.
It includes different placeholders for loading, errors, or if content data is not available.
The content can be set to autoplay and loop as per the provided props.

How to use it:
Include this component in your Vue application to show a showcase footer content. You need to provide the URL
to the content JSON and a placeholder image URL to show during loading or in case of an error.
-->
<template>
  <div class="bg-container">
    <Transition
      mode="out-in"
      @before-appear="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <div
        :key="showcaseKey"
        class="sub-container"
      >
        <div
          class="left-section"
          :style="{ backgroundColor: content.color }"
        >
          <div
            v-if="!isMobile"
            class="content-container"
            :style="{ backgroundColor: content.color }"
          >
            <h2>NEXT UP</h2>
            <h1>{{ content.title }}</h1>
            <p class="description">
              {{ content.description }}
            </p>
            <div
              :class="[
                'buttons-container',
                contentAvailable ? 'tw-opacity-100': 'tw-opacity-0'
              ]"
            >
              <a :href="`${content.readCaseUrl}`">
                <div class="button-read">
                  Read case study
                </div>
              </a>
              <a :href="`${sitePath}work/`">
                <div class="button-all">
                  See all work
                </div>
              </a>
            </div>
          </div>
          <div
            v-else
            class="image-and-overlay"
          >
            <img
              class="project-img"
              :src="content?.image ?? 'https://picsum.photos/840/612'"
              :alt="content?.altImage ?? 'showcase project'"
            >
            <div
              class="gradient-overlay"
              :style="gradientStyle"
            />
          </div>
        </div>
        <div
          class="right-section"
          :style="{ backgroundColor: content.color }"
        >
          <div
            v-if="!isMobile"
            class="image-and-overlay"
          >
            <img
              class="project-img"
              :src="content?.image ?? 'https://picsum.photos/840/612'"
              :alt="content?.altImage ?? 'showcase project'"
            >
            <div
              class="gradient-overlay"
              :style="gradientStyle"
            />
          </div>
          <div
            v-else
            class="content-container"
            :style="{ backgroundColor: content.color }"
          >
            <h2>NEXT UP</h2>
            <h1>{{ content.title }}</h1>
            <p class="description">
              {{ content.description }}
            </p>
            <div
              :class="[
                'buttons-container',
                contentAvailable ? 'tw-opacity-100': 'tw-opacity-0'
              ]"
            >
              <a :href="`${content.readCaseUrl}`">
                <div class="button-read">
                  Read case study
                </div>
              </a>
              <a :href="`${sitePath}work/`">
                <div class="button-all">
                  See all work
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount, inject } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import axios from 'axios';
import { uuid } from 'vue3-uuid';
import 'animate.css';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  id: {
    type: String,
    default: null,
  }
});
const baseUrl = process.env.APP_URL;
const isMobile = ref(false);
const showcaseKey = ref(uuid.v4());

const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
const siteID = computed(() => wordpressData?.value?.blog_id ?? 1);
const sitePath = computed(() => wordpressData?.value?.multisite_path ?? '/');

// Use sitePath for consistent path management

function beforeEnter(el: any) {
  el.classList.add('animate__animated', 'animate__fadeIn', 'animate__faster');
}

function enter(el: any, done: () => void) {
  el.addEventListener('animationend', done);
}

function leave(el: any, done: () => void) {
  el.classList.add('animate__animated', 'animate__fadeOut', 'animate__faster');
  el.addEventListener('animationend', done);
}

const gradientStyle = computed(() => ({
  background: `linear-gradient(${isMobile.value ? 'to top' : 'to right'}, ${content.value.color} 15%, transparent 85%)`,
}));

const processData = (value: any): any => {
  sessionStorage.setItem('current_post_id', value.id);
  return {
    title: value.title,
    description: value.description,
    image: value.image ? value.banner.url : null,
    altImage: value.image ? value.banner.alt : 'showcase project',
    color: value.feature_color,
    readCaseUrl: value.hyperlink,
  };
};

function onResize() {
  isMobile.value = window.innerWidth <= 1024;
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
  fetch();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

const contentAvailable = ref(false);
const content = ref({
  title: 'Alphapod',
  description: 'Loading new content...',
  image: 'https://picsum.photos/840/612/?blur=10',
  altImage: 'placeholder',
  color: 'black',
  readCaseUrl: baseUrl,
});

function fetch() {
  interface Params {
    site_id: any;
    current_post_id: string | null;
    exclude_post_id?: string;
  }

  const params: Params = {
    site_id: siteID.value,
    current_post_id: sessionStorage.getItem('current_post_id'),
  };


  if (props.id) {
    params['exclude_post_id'] = props.id;
  }

  axios
    .get('/wp-json/api/v1/works-showcase', {
      params: params
    })
    .then(response => {
      if (response.status == 200) {
        contentAvailable.value = true;
        content.value = processData(response.data);
        showcaseKey.value = uuid.v4();
      }
    });
}
</script>

<style scoped>
.bg-container {
  position: relative;
  width: 100%;
  background: #000;
}

.sub-container {
  display: flex;
  height: 612px;
  width: 100%;
}

.image-and-overlay {
  position: relative;
  flex-shrink: 1;
}

.project-img {
  display: flex;
  object-fit: cover;
  min-height: 612px;
  height: 100%;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

.left-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.right-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.content-container {
  flex-grow: 1;
  max-width: 542px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.content-container h2 {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 175% */
  letter-spacing: 0.75px;
  text-transform: uppercase;
  opacity: 0.75;
  /* Slightly transparent */
  margin-bottom: 8px;
  /* Space below the subheading */
}

.content-container h1 {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  /* 125% */
  letter-spacing: -3px;
  margin-bottom: 16px;
  /* Space below the title */
}

.description {
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  margin-bottom: 64px;
}

.buttons-container {
  display: flex;
  /* Aligns buttons in a row */
  gap: 16px;
  /* Space between buttons */
}

.button-read {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  background: #FFF;
  color: #242424;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  transition: opacity 0.3s ease;
  /* Smooth transition for hover effect */
}

.button-all {
  padding: 1rem 32px;
  /* Padding inside buttons */
  color: inherit;
  /* Inherits white color from parent */
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  border: 2px solid rgba(255, 255, 255, 0.50);
}

.button-read:hover {
  opacity: 0.8;
}

.button-all:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1024px) {

  .sub-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 64px;
    padding-top: 24px;
    color: white;
  }

  .left-section {
    justify-content: center;
  }

  .right-section {
    justify-content: center;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .content-container h1 {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }



  .content-container h2 {
    color: var(--Neutrals-Nobel, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .description {
    color: var(--Neutrals-Nobel, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .button-read {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
  }

  .button-all {
    color: var(--Neutrals-White, #FFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: center;
    border-radius: 10px;
  }

  .project-img {
    display: flex;
    height: auto;
    min-height: 219px;

  }

  .gradient-overlay {
    position: absolute;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 30%, transparent 70%);
  }
}
</style>
