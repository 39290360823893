<!--
Author: Joachim <joachim@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Home Page

Description:
This component displays stay ahead messages. The component automatically adjusts based on the window size,

How to use it:
<StayAheadMessage
/>
-->
<template>
  <section class="stay-ahead">
    <div class="text-content">
      <h1>Stay ahead or get left behind.</h1>
      <p>
        Innovation has evolved from a luxury to a necessity for survival in today's economy. Businesses that embrace digital transformation grow and succeed,
        while those that do not risk fading into obscurity.
      </p>
      <br>
      <p>
        Since 2009 we’ve been working closely with businesses of all shapes and sizes to create digital solutions that engage audiences, convert attention into
        measurable results, and optimize for sustainable growth.
      </p>
      <br>
      <p>
        We don’t just build apps or websites. Not just. We involve ourselves in your business and align our expertise with your goals and aspirations. We put on
        our entrepreneur and user hats, challenge assumptions, and ask difficult questions to deliver real value to your business.
      </p>

      <div class="bottom-text">
        <h2>We win when you win 🏆</h2>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
</script>

<style scoped>
.stay-ahead {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  background-color: #fff;
  z-index: 1; /* Lower z-index to make sure the next section can overlap */
  will-change: transform;
}

.stay-ahead + section {
  position: relative; /* Ensure next section is positioned relative to the current one */
  z-index: 2; /* This makes sure the next section overlaps the current one */
}

.text-content {
  max-width: 1000px;
  margin: 120px 24px;
}
.text-content h1 {
  font-family: 'Inter', sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -3px;
  margin-bottom: 64px;
}

.text-content p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.image-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.image-container.double {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  gap: 34px;
}

.image-large {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-small {
  width: 100%;
  height: 50%;
}

.bottom-text {
  margin-top: 64px;
}

.bottom-text h2 {
  font-family: 'Inter', sans-serif;
  align-items: left !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
  align-items: left !important;
}

@media screen and (max-width: 820px) {
  .text-content {
    margin: 64px 80px;
  }

  .text-content h1 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
  }
  .bottom-text h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .image-container.double {
    gap: 16px;
  }

  .bottom-text p,
  .text-content p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .image-container {
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .text-content {
    margin: 64px 24px;
  }
}

@media (max-width: 900px) {
  .section {
    position: relative;
    display: flex;
  }
}
</style>
