<template>
  <div>
    <BlankScreen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      current-section="blogs"
      @get-in-touch="showForm = true"
    />
    <div class="spacing" />
    <BlogLandingHeader />
    <BlogLandingContent
      v-if="wordpressData.blog_tags.length > 0"
      :tags="wordpressData.blog_tags"
      :tag="wordpressData.tag_data"
    />
    <div
      v-else
      class="sgDesktop:tw-h-[100vh] tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-4"
    >
      <p class="sgDesktop:tw-text-h3-semi-bold-desktop tw-text-h3-semi-bold-mobile tw-text-ap-whisper">
        No blog yet, stay tuned...
      </p>
      <img
        class="tw-w-1/2"
        :src="$cdn('blog/work-in-progress.png')"
        alt="Work in progress"
      >
    </div>
    <ContactUsFooter @open-form="handleFormRequest" />
    <Footer @get-in-touch="openForm" />
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <PrivacyPolicyBanner />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import AppBarLight from '../components/general/AppBarLight.vue';
import BlankScreen from '../components/general/BlankScreen.vue';
import ContactUsFooter from '../components/general/ContactUsFooter.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import Footer from '../components/general/Footer.vue';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import BlogLandingContent from '../components/blog/BlogLandingContent.vue';
import BlogLandingHeader from '../components/blog/BlogLandingHeader.vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PrivacyPolicyBanner from '../components/general/PrivacyPolicyBanner.vue';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const showForm = ref(false);

const contactInfo = {
  name: '',
  email: '',
  company: ''
};

const handleFormRequest = (payload: any) => {
  contactInfo.name = payload.name;
  contactInfo.email = payload.email;
  contactInfo.company = payload.company;
  showForm.value = true;
};

const openForm = () => {
  showForm.value = true;
};

const closeForm = () => {
  showForm.value = false;
};

withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const queryParams = ref('');

onMounted(() => {
  const params = new URLSearchParams(window.location.search);
  queryParams.value = params.get('filter') || '';
});

</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}

.hidden {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 164px;
  }
}
</style>
