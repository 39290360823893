<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Careers Page
Description:
This component lists the open job positions for the Careers Page, allowing users to view detailed information about
each position by expanding the relevant section. It provides interactive features for mobile users, such as toggleable
sections and smooth scroll animations to enhance user experience. The component also emits an event when users click to apply for a position.

How to use it:
<OpenPosition :services="servicesArray" :query="selectedServiceSlug" @applyJob="handleApplyJob" />
Pass `services` as an array of service objects, where each object should contain information about an open position,
such as `slug`, `title`, `subtitle`, `location`, and `details`. Optionally, use the `query` prop to specify
which position should be automatically scrolled to. The `applyJob` event can be used to handle the application logic.
-->
<template>
  <div
    id="open-position"
    class="layout-container"
  >
    <div class="sub-container">
      <header class="main-title">
        <h1>{{ mainTitle }}</h1>
      </header>
      <section
        v-for="(service, index) in services"
        :id="service.slug"
        :key="index"
        class="service-section"
      >
        <div
          :class="{'active': selectedPosition === index}"
          style="display: flex; align-items: center;"
        >
          <div
            class="left-section"
            @click="onClickToggle(index)"
          >
            <h4>{{ service.subtitle }}</h4>
            <h2 class="service-title">
              {{ service.title }}
            </h2>
            <div class="location-container">
              {{ service.location }}
            </div>
          </div>
          <img
            v-if="isMobile"
            :class="`caret-${index}`"
            :src="$cdn('icons/icon_regular_caret_down.svg')"
            alt="Caret down"
          >
        </div>
        <div
          :id="`job-position-${index}`"
          class="right-section"
        >
          <div
            v-for="(detail, detailIndex) in service.details"
            :key="`detail-${detailIndex}`"
            :class="{'service-detail': true, 'first-detail': detailIndex === 0}"
          >
            <a
              v-if="detail.url"
              href="#"
              class="link"
              @click="emit('applyJob', service)"
            >Apply for this position</a>

            <div v-if="!detail.url">
              <h3>
                {{ detail.title }}
              </h3>
              <div
                v-for="(description, descriptionIndex) in detail.description"
                :key="`description-${descriptionIndex}`"
                class="service-description-container"
              >
                <h4>{{ description.subtitle }}</h4>
                <div v-if="description.more_info">
                  <div
                    v-for="(text, i) in description.details"
                    :key="`description-${i}`"
                    class="service-description more-info"
                    v-html="text"
                  />
                </div>
                <p
                  v-for="(text, i) in description.details"
                  v-else
                  :key="`description-${i}`"
                  class="service-description"
                >
                  {{ text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">

import { ref, onMounted, nextTick, computed, watch } from 'vue';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
// import services from '../assets/career-open-position.json';


gsap.registerPlugin(ScrollToPlugin);

const emit = defineEmits(['applyJob']);
const mainTitle = ref('Open positions');

const props = defineProps({
  services: {
    type: Array,
    required: true
  },
  query: {
    type: String,
    default: ''
  },
});
const slugs = props.services.map((service) => service.slug);
const baseUrl = window.location.pathname;

onMounted(() => {
  if (props.query.length > 0) {
    nextTick(() => {
      let element = document.getElementById('open-position');
      if (slugs.includes(props.query)) {
        element = document.getElementById(props.query);
        if (isMobile.value) {
          const index = slugs.findIndex((slug) => {
            return slug === props.query;
          });
          // activePosition.value = index;
          // rotateCaret(index);
          console.log(index);
        }
      }

      const urlWithoutParams = window.location.origin + window.location.pathname;
      // Replace history state from /careers/?goto=slug to /careers
      window.history.replaceState({}, document.title, urlWithoutParams);

      gsap.to(window, {
        scrollTo: {
          y: element?.offsetTop,
          autoKill: false
        },
        duration: 1 ,
        onComplete: () => {
          element?.scrollIntoView({behavior: 'smooth'});
          setTimeout(() => {
            const scrolledCareer = props.services.find((service: any) => service.slug === props.query);
            console.log('scrolledCareer: ', scrolledCareer);
            if (scrolledCareer) {
              emit('applyJob', scrolledCareer);
            }
          }, 700);
        }
      });
    });
  }
  if (isMobile.value) {
    watch(activePosition, (newVal, oldVal) => {
      rotateCaret(oldVal);
      const oldElement = document.getElementById(`job-position-${oldVal}`);
      if (oldElement) {
        gsap.to(oldElement, { height: 0, duration: 0.3, overflow: 'hidden', ease: 'none', onStart: () => {
          //while the animation is playing follow the tick and scroll up and do this only when oldval is less than newval
          if (oldVal < newVal) {
            // oldElement.scrollIntoView({behavior: 'smooth'});
            gsap.to(window, {scrollTo: {y: oldElement.offsetTop - 50, autoKill: false}, duration: 0.3, ease: 'none'});
          }
        }, onComplete: () => {
          selectedPosition.value = newVal;
        }});
      }
      if (newVal >= 0) {
        rotateCaret(newVal);
        const newElement = document.getElementById(`job-position-${newVal}`);
        if (newElement) {
          newElement.style.height = 'auto'; // Reset to auto before measuring
          const height = newElement.scrollHeight + 'px'; // Get the full height
          newElement.style.height = '0px'; // Set back to 0 before animating
          gsap.to(newElement, { height: height, duration: 0.3, overflow: 'hidden', ease: 'none', onComplete: () => {
            selectedPosition.value = newVal;
          }});
        }
      }
    }, { immediate: true });
  }
});

const activePosition = ref<number>(-1);
const selectedPosition = ref<number>(-1);

function onClickToggle(index: number) {
  if (isMobile.value) {
    if (activePosition.value === index) {
      activePosition.value = -1;
    }else {
      activePosition.value = index;
    }
  }
}

function rotateCaret(index: number) {
  if (isMobile.value && index >= 0) {
    if (activePosition.value === index) {
      //active
      gsap.to('.caret-' + index + '', {
        duration: 0.3,
        ease: 'none',
        rotate: 180,
      });
    }else {
      //not active
      gsap.to('.caret-' + index + '', {
        duration: 0.3,
        ease: 'none',
        rotate: 0,
      });
    }
  }
}

const isMobile = computed(() => {
  return window.innerWidth <= 832;
});

</script>

<style scoped>

.more-info :deep(a) {
  color:#007AFF;
}

.accordion-item-enter-active, .accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter, .accordion-item-leave-to {
  height: 0 !important;
}

.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 160px 24px;
  background-color: #FAFAFA;
}

.sub-container{
  max-width: 1280px;
  margin: 0 48px;
}

.main-title {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -3px;
  margin-bottom: 16px;
}

.service-section {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 32px 0;
  margin-bottom: 32px;
  gap: 64px;
}

.location-container {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #E6E6E6;
  color: var(--Neutrals-Dim-Grey, #646464);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  letter-spacing: -0.25px;
  margin-top: 8px;
}

.left-section {
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
}

.left-section h4 {
  color: #969696;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.left-section h2 {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.left-section img {
  width: 240px;
  height: auto;
}

.right-section {
  display: flex;
  flex-direction: column;
}

.service-detail:not(.first-detail) {
  border-top: 1px solid #ccc;
  padding-top: 32px;
}

.service-description-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.right-section h3{
  align-self: stretch;
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.right-section h4 {
  color: #242424;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
}

.right-section p {
  padding-left: 20px;
}

.right-section p, .right-section .more-info {
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 6px;
  position: relative;
}

.right-section p::before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  color: #242424;
}


.right-section .link {
  color: #007AFF;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 32px;
}

@media screen and (max-width: 1024px) {
  .layout-container {
    padding: 80px 80px 64px 80px;
  }

  .sub-container{
    max-width: 1280px;
    margin: 0;
  }

  .service-section {
    flex-direction: column;
    gap: 0;
    padding: 32px 0;
    margin-bottom: 0;
  }

  .service-description-container {
    margin-bottom: 16px;
  }

  .service-description-container h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .main-title {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    margin-bottom: 32px;
  }

  .left-section {
    position: relative;
    top: 0;
    min-width: 0;
    width: 100%;
  }

  .left-section h4 {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .left-section h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 24px;
  }

  .service-detail{
    margin-top: 32px;
  }

  .service-detail:not(.first-detail) {
    border-top: none;
    padding-top: 0px;
  }

  .right-section {
    gap: 0px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }

  .right-section.active{
    height: 100%;
  }

  .right-section h3{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin-bottom: 8px;
  }

  .right-section p, .right-section .more-info{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .right-section .link {
    color: var(--Primary-Dodger-Blue, #007AFF);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    margin-bottom: 32px;
  }

}

@media screen and (max-width: 480px) {
  .layout-container {
    padding: 64px 24px 64px 24px;
  }

}

</style>
