<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: Project Page

Description:
This component provides an overview of the project's involvement, including a title, details, and
a series of component tiles. It also includes links to the "Our Services" and "Get in touch" pages.

How to use it:
<ProjectInvolvement
  :obj="obj"
  @get-in-touch="emit('get-in-touch')"
/>
-->
<template>
  <section class="main-container">
    <section class="title-container">
      <h2 class="title">
        How we were involved
      </h2>
      <p class="details">
        {{ obj.details }}
      </p>
    </section>
    <section class="component-tile">
      <div class="empty-pad" />
      <div
        v-for="tile in obj.tileBlocks"
        :key="tile.alt"
        class="component-instance"
      >
        <img
          :src="$cdn(tile.src)"
          :alt="tile.alt"
        >
        <h2>{{ tile.title }}</h2>
        <div
          v-for="(desc, index) in tile.description"
          :key="`${tile.alt}-${index}`"
        >
          <p>{{ desc }}</p>
        </div>
      </div>
    </section>
    <section class="links-section">
      <a
        href="#"
        class="link-box"
        @click="emit('get-in-touch')"
      >
        <div class="link-content">
          <h2>Interested?</h2>
          <p>Get in touch with us</p>
        </div>
        <img
          class="arrow"
          :src="$cdn('icons/arrow-right.svg')"
          alt="Arrow right"
        >
      </a>

      <a
        :href="`${sitePath}services/`"
        class="link-box"
      >
        <div class="link-content">
          <h2>Our services</h2>
          <p>Ways we can help you</p>
        </div>
        <img
          class="arrow"
          :src="$cdn('icons/arrow-right.svg')"
          alt="Arrow right"
        >
      </a>
    </section>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, inject } from 'vue';
import { fadeInEffect } from '../../support/helper';

const wordpressData = inject('wordpressData');
const mainSite = computed(() => wordpressData?.value?.is_main_site ?? true);
const siteID = computed(() => wordpressData?.value?.blog_id ?? 1);
const sitePath = computed(() => wordpressData?.value?.multisite_path ?? '/');


const dataProcess = (value: any) => {
  return {
    details: value.description,
    tileBlocks: [
      {src: 'icons/icon_compass.svg', alt:'icon_compass', title:'Discovery', description:[value.discovery.description]},
      {src: 'icons/icon_strategy.svg', alt:'icon_strategy', title:'Strategy', description:[value.strategy.description]},
      {src: 'icons/icon_brush.svg', alt:'icon_brush', title:'Design', description:[value.design.description]},
      {src: 'icons/icon_cloud.svg', alt:'icon_cloud', title:'Development', description:[value.development.description]},
    ],
  };
};

const obj = computed(()=> dataProcess(props.data));

const emit = defineEmits(['get-in-touch']);

const props = defineProps({
  data: {
    type: Object,
    default: () => {
      return {

      };
    },
  },
});

onMounted(() => {
  fadeInEffect('.title');
  fadeInEffect('.details');
  fadeInEffect('.component-tile');
  fadeInEffect('.links-section');
});

</script>

<style scoped>

.main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
}

.title-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 720px;
  margin: 0 24px;
}

.title{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  padding-top: 15%;
  padding-bottom: 32px;
  text-align: start;

}

.details{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-bottom: 16px;
}

.component-tile {
  margin-top: 80px;
  margin-bottom: 67px;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  width: 100%;
  justify-content: start;
}

.component-tile::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.component-tile .empty-pad {
  content: '';
  width: 320px; /* Initial padding space */
  flex-shrink: 0; /* Prevents it from shrinking */
}

.component-instance {
  flex-shrink: 0;
  margin-left: 10px;
  margin-right: 10px;
}


.component-instance{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  max-width: 426px;
  min-height: 426px;
  border: 1px solid #E6E6E6;
  background-color: #FFF;
  padding: 32px;
}

.component-instance h2{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
  margin-bottom: 24px;
}

.component-instance p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 24px;
}

.component-instance img{
  width: 32px;
  height: 32px;
  margin-bottom: 24px;
}

.links-section {
  display: flex;
  justify-content: center;
  padding-bottom: 160px;
  gap: 24px;
}

.link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  flex-grow: 1;
  padding: 16px 24px;
  width: 350px;
  background-color: white; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  border: 1px solid #E6E6E6;
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
}

.link-box:hover {
  background-color: #f0f0f0; /* Slightly darker gray on hover */
}

.link-box h2 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
}

.link-box p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.link-content{
  flex-grow: 1;
}

@media screen and (min-width: 2160px) {
  .component-tile {
    justify-content: center;
  }
}

@media screen and (max-width: 820px) {
  .title{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    padding-bottom: 16px;
    text-align: start;

  }
  .details{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    padding-bottom: 16px;
  }

  .empty-pad{
    display: none;
  }

  .component-tile{
    justify-content: flex-start;

  }

  .component-instance{
    max-width: 310px;
    min-height: 308px;
  }

  .component-instance h2{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    margin-bottom: 24px;
  }

  .component-instance p{
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.25px;
    margin-bottom: 16px;
  }

  .component-instance img{
    width: 28px;
    height: 28px;
    margin-bottom: 24px;
  }

  .links-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80px;
    gap: 16px;
    width: 100%;
    padding: 0 24px;
    padding-bottom: 64px;
  }

  .link-box {
    width: 100%;
  }

  .link-box h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .link-box p {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

</style>
