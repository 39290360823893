<template>
  <div>
    <BlankScreen v-show="showForm" />
    <AppBarLight
      :class="`${showForm ? 'hidden' : ''}`"
      @get-in-touch="openForm"
    />
    <div class="spacing" />
    <!-- Content Here -->
    <!-- Header -->
    <InternshipProgramHeader :header-image="props.wordpressData.header_image" />
    <!-- Hello -->
    <InternshipProgramContentTemplate
      :with-left-right-padding="true"
    >
      <InternshipProgramContentHello />
    </InternshipProgramContentTemplate>
    <!-- Universities Logo Carousel -->
    <UniversitiesCarousel />
    <!-- This is the way -->
    <InternshipProgramContentTemplate
      :is-grey-bg="true"
      :with-bottom-padding="true"
      :with-left-right-padding="true"
    >
      <InternshipProgramContentJob
        :interns-job="internsJob"
        @apply-intern-job="handleApplyInternJob"
      />
    </InternshipProgramContentTemplate>
    <!-- DVO Showcase -->
    <InternshipProgramContentTemplate
      :with-bottom-padding="true"
      :with-left-right-padding="true"
    >
      <DVOShowcase />
    </InternshipProgramContentTemplate>
    <!-- Craftable Showcase -->
    <InternshipProgramContentTemplate
      :is-grey-bg="true"
      :with-bottom-padding="true"
      :with-left-right-padding="true"
    >
      <CraftableShowcase />
    </InternshipProgramContentTemplate>
    <!-- Work made fun -->
    <InternshipProgramContentTemplate
      :is-grey-bg="true"
      :with-bottom-padding="true"
    >
      <InternshipProgramContentWork
        :interns-job="internsJob"
        @apply-intern-job="handleApplyInternJob"
      />
    </InternshipProgramContentTemplate>

    <!-- End of Content Here -->
    <transition name="fade">
      <ContactUsHolder
        v-if="showForm"
        :name="contactInfo.name"
        :email="contactInfo.email"
        :company="contactInfo.company"
        @close-form="closeForm"
      />
    </transition>
    <transition name="fade">
      <JobApplicationHolder
        v-if="showJobForm"
        :position="selectedInternJob?.title"
        :department="selectedInternJob?.department"
        @close-job-form="showJobForm = false"
      />
    </transition>
    <Footer @get-in-touch="openForm" />
  </div>
</template>

<script lang="ts" setup>
import AppBarLight from '../components/general/AppBarLight.vue';
import BlankScreen from '../components/general/BlankScreen.vue';
import InternshipProgramHeader from '../components/internship_program/InternshipProgramHeader.vue';
import Footer from '../components/general/Footer.vue';
import ContactUsHolder from '../components/contact_us_form/ContactUsHolder.vue';
import InternshipProgramContentTemplate from '../components/internship_program/InternshipProgramContentTemplate.vue';
import InternshipProgramContentHello from '../components/internship_program/InternshipProgramContentHello.vue';
import InternshipProgramContentJob from '../components/internship_program/InternshipProgramContentJob.vue';
import DVOShowcase from '../components/internship_program/DVOShowcase.vue';
import CraftableShowcase from '../components/internship_program/CraftableShowcase.vue';
import InternshipProgramContentWork from '../components/internship_program/InternshipProgramContentWork.vue';
import JobApplicationHolder from '../components/job_application_form/JobApplicationHolder.vue';
import UniversitiesCarousel from '../components/internship_program/UniversitiesCarousel.vue';

import { ref, watch } from 'vue';
import InternshipJob from '../model/InternshipJob';

const props = withDefaults(defineProps<{
  wordpressData?: any | null,
}>(), {
  wordpressData: null,
});

const showForm = ref(false);
const showJobForm = ref(false);
const selectedInternJob = ref<InternshipJob | null>(null);

const contactInfo = {
  name: '',
  email: '',
  company: '',
};

const openForm = () => {
  showForm.value = true;
};
const closeForm = () => {
  showForm.value = false;
};

watch(showJobForm, (value) => {
  if (value) {
    openModal();
  }else{
    closeModal();
  }
});

function openModal() {
  document.body.style.overflow = 'hidden';
}

function closeModal() {
  document.body.style.overflow = 'auto';
}

function handleApplyInternJob(job: InternshipJob) {
  showJobForm.value = true;
  selectedInternJob.value = job;
}

const internsJob: InternshipJob[] = [
  {
    title: 'Intern (Design)',
    department: 'Design',
    category: 'DESIGN',
    city: 'Kuala Lumpur',
    responsibilities: [
      'Assist design team daily with tasks and deliverables',
    ],
    requirements: [
      "Candidate should be pursuing a Bachelor's Degree / Post-Graduate Diploma / Diploma in Digital Media Design / Multimedia Design or equivalent",
      'Good knowledge of Figma for Ul design',
      'Good knowledge of visual design, typography, composition and colour',
      'Extremely detail-oriented. Strives for pixel perfection at all times',
      'Good communication skills',
      'Good problem-solving skills',
    ],
    extraPoints: [
      'Good knowledge of Adobe Illustrator, Photoshop, After Effects',
    ],
    weOffer: [
      'Mentorship from experienced developers and designers',
      'A pathway to potential full-time employment based on performance',
      'Hands-on experience working on real-world projects',
    ],
    applyLink: 'intern-design',
  },
  {
    title: 'Intern (Software Engineering)',
    department: 'SOFTWARE ENGINEERING',
    category: 'IT',
    city: 'Kuala Lumpur',
    responsibilities: [
      'Assist development team daily with tasks and deliverables',
    ],
    requirements: [
      "Candidate should be pursuing a Bachelor's Degree / Post-Graduate Diploma / Professional Degree in Computer Science / Computer Engineering / Information Technology or equivalent",
      'Proficient in frameworks or languages such as Bootstrap, jQuery, HTML, CSS, and JavaScript',
      'Basic knowledge of server-side programming such as PHP, Node.js',
      'Familiarity with version control (Git/GitHub) workflows',
      'Passion for gaining hands-on experience in real-world projects',
      'Ability to articulate ideas, ask questions, and collaborate effectively in a team environment',
      'Ability to prioritise and complete assigned tasks within deadlines',
    ],
    extraPoints: [],
    weOffer: [
      'Mentorship from experienced developers and designers',
      'A pathway to potential full-time employment based on performance',
      'Hands-on experience working on real-world projects',
    ],
    applyLink: 'intern-software-engineering',
  },
];
</script>

<style scoped>
.spacing {
  height: 320px;
  width: 100%;
}

@media screen and (max-width: 820px) {
  .spacing {
    height: 136px;
  }
}
</style>
