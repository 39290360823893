<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Home Page

Description:
This component provides a welcoming message and links to the About and Work pages.

How to use it:
<WelcomeMessage />
-->
<template>
  <div class="content-container">
    <div class="sub-container">
      <div class="text-section">
        <h1>Welcome to Alphapod.</h1>
        <p>
          We are a digital product studio headquartered in Malaysia. We imagine, create and launch digital experiences that delight users, impact lives, and
          change the world.
        </p>
        <br>
        <p>
          We’ve come a long way since our founding in 2009—from building our first mobile app for the iPhone 3GS to working with some of the world’s most
          recognisable brands today.
        </p>
      </div>
      <div class="links-section">
        <a
          href="/about"
          class="link-box"
        >
          <div class="link-content">
            <h2>About us</h2>
            <p>Who we are, and why we do what we do.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
        <a
          href="/work"
          class="link-box"
        >
          <div class="link-content">
            <h2>Our work</h2>
            <p>Nothing speaks louder.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
        <a
          href="/services"
          class="link-box"
        >
          <div class="link-content">
            <h2>Our services</h2>
            <p>From idea to launch and back again.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
        <a
          href="/careers"
          class="link-box"
        >
          <div class="link-content">
            <h2>Join our team</h2>
            <p>Do the best work of your life here.</p>
          </div>
          <img
            class="arrow"
            :src="$cdn('icons/arrow-right.svg')"
            alt="Arrow right"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onMounted } from 'vue';
gsap.registerPlugin(ScrollTrigger);

onMounted(() => {
  gsap.fromTo(
    '.text-section',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: '.text-section',
        start: 'top 75%',
        toggleActions: 'play none none none',
      },
    }
  );
  gsap.fromTo(
    '.links-section',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: '.links-section',
        start: 'top 75%',
        toggleActions: 'play none none none',
      },
    }
  );
});
</script>

<style scoped>
.content-container {
  color: #242424;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  background-color: #fafafa;
  padding: 160px 0px;
}

.sub-container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  margin: 24px 24px;
}

.text-section h1 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
  margin-bottom: 8px;
}

.text-section p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  font-style: normal;
}

.links-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  margin-top: 120px;
}

.link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  flex-grow: 1;
  padding: 16px 24px;
  background-color: #f0f0f0; /* Light gray background */
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background color change on hover */
}

.link-box:hover {
  background-color: #e0e0e0; /* Slightly darker gray on hover */
}

.link-box h2 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  font-style: normal;
  color: #242424;
}

.link-box p {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.link-content {
  flex-grow: 1;
}
.arrow {
  height: 16px;
  width: auto;
  color: #969696;
  margin-left: 12px;
}

@media (max-width: 820px) {
  .links-section {
    grid-template-columns: 1fr; /* Stack the boxes on smaller screens */
  }
}

@media screen and (max-width: 820px) {
  .sub-container {
    margin: 24px 80px;
  }

  .content-container {
    padding: 80px 0px;
  }

  .links-section {
    margin-top: 80px;
    gap: 16px;
  }

  .link-box {
    border-radius: 10px;
  }

  .link-box h2 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .link-box p {
    color: var(--Neutrals-Nobel, #969696);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .text-section h1 {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .text-section p {
    color: var(--Neutrals-Nero, #242424);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}

@media screen and (max-width: 480px) {
  .sub-container {
    margin: 24px;
  }

  .content-container {
    padding: 64px 0px;
  }

  .links-section {
    margin-top: 64px;
    gap: 16px;
  }
}
</style>

/* 0.5rem 0.875rem 1rem 1.2rem 1.75rem 2rem 3rem 4rem 7.5rem 10rem */
