<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Joachim <joachim@alphapod.com>
Module: Blog Page
Description:
This component displays a list of blog posts, allowing users to filter posts by category and load more posts as needed.
It provides a responsive design that adjusts the category selection view based on the device type (using a Swiper slider
on tablets and buttons otherwise). The component features a "Load more" button for paginated loading, ensuring users can easily explore additional content.

How to use it:
<BlogLandingContent :tags="tagsArray" :tag="selectedTag" />
Pass `tags` as an array of `BlogTag` objects to define available categories, and optionally pass `tag` to set the initially selected category.
The component will display blog cards for the selected category and allow users to load more posts.
-->

<template>
  <div class="main-container">
    <div class="sub-container">
      <div
        class="categories scrollbar-hide"
        :class="[
          categories.length <= 1 ? 'hidden' : '',
          { 'centered-tab': isCenteredTab }
        ]"
      >
        <button
          v-for="(category, index) in categories"
          :key="`category-${index}`"
          class="category-button"
          :class="{ 'is-selected': selectedCategory === category }"
          @click="onSelectCategory(category)"
        >
          {{ category }}
        </button>
      </div>
      <div class="blog-card-container">
        <BlogShowcase
          :category="selectedCategory"
          :blogs="filteredBlogs"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { getBlogs } from '../../support/blogService';
import { BlogItem, BlogTag } from '../../support/model';
import BlogShowcase from './BlogShowcase.vue';


const props = defineProps({
  // wordpressData: {
  //   type: Object,
  //   required: true,
  // },
  tags: {
    type: Array as () => BlogTag[],
    default: () => [],
  },
  // content: {
  //   type: Array as () => ContentItem[],
  //   default: () => []
  // },

  tag: {
    type: Object as () => BlogTag,
    default: undefined,
  },
  query: {
    type: String,
    default: '',
  },
});

// interface LayoutItem {
//   acf_fc_layout: string;
//   value: string;
// }

// interface ContentItem {
//   acf_fc_layout: string;
//   layout: LayoutItem[];
// }


// Blog state
const selectedCategory = ref('All');
const totalCategory = ref(0);

const categories = computed(() => {
  return ['All', ...props.tags.map((tag) => tag.name)];
});

const page = ref(1);
const blogs = ref<BlogItem[]>([]);
const cacheBlogs = ref<BlogItem[]>([]);
const hasNextPage = ref(false);
const isCenteredTab = ref(false);

// const estimateReadingTime = computed(() => {
//   // Ensure content is an array and extract the 'value' fields
//   const rawContent = props.content.flatMap(item => {
//     if (Array.isArray(item.layout)) { // Ensure item.layout is an array
//       return item.layout
//         .filter(subItem => subItem.value) // Filter out null or undefined values
//         .map(subItem => subItem.value);
//     }
//     return []; // Return an empty array if item.layout is not an array
//   }).join(' '); // Join all text content into one string

//   console.log('Extracted content:', rawContent);

//   // Check if rawContent is empty
//   if (!rawContent.trim()) {
//     return '0 mins read'; // Return a fallback message if there's no content
//   }

//   // Calculate word count
//   const words = rawContent.trim().split(/\s+/).filter(Boolean).length;
//   console.log('Word count:', words); // Log the word count

//   // Calculate reading time (200 words per minute)
//   const wordsPerMinute = 200;
//   const time = Math.max(Math.ceil(words / wordsPerMinute), 1);
//   console.log('Calculated time (in minutes):', time); // Log the calculated time

//   // Adjusted output to show "minute" or "minutes"
//   return `${time} minute${time > 1 ? 's' : ''} read`;
// });

// Featured blogs (using the same data as blogs)

// Fetch blogs based on category
async function fetchBlogs(categorySlug: string, pageNum = 1) {
  const response = await getBlogs(pageNum, 8, categorySlug);
  blogs.value = pageNum === 1 ? response.blogs : blogs.value.concat(response.blogs);
  cacheBlogs.value = blogs.value;
  hasNextPage.value = response.has_next_page;
}

// Category selection handler
function onSelectCategory(category: string) {
  if (selectedCategory.value !== category) {
    selectedCategory.value = category;
    page.value = 1;
    if (category === 'All') {
      fetchBlogs(getSlug(category));
    }else{
      blogs.value = cacheBlogs.value.filter((blog) => blog.tags.includes(category));
    }
  }
}

// Get slug by category name
function getSlug(name: string): string {
  return props.tags.find((tag) => tag.name === name)?.slug || '';
}

// Filtered blogs based on category
const filteredBlogs = computed(() => {
  if (selectedCategory.value === 'All') {
    return blogs.value;
  }
  return blogs.value.filter((blog) => blog.tags.includes(selectedCategory.value));
});

// Initial load
onMounted(() => {
  // Parse the query string to get the 'category' parameter
  const urlParams = new URLSearchParams(window.location.search);
  const categorySlug = urlParams.get('category');

  if (categorySlug) {
    // Find the matching category name from the tags
    const matchingCategory = props.tags.find((tag) => tag.slug === categorySlug)?.name || 'All';
    selectedCategory.value = matchingCategory;

    // Fetch blogs for the selected category
    fetchBlogs(categorySlug);
  } else {
    // Default to 'All' category if no query param exists
    fetchBlogs(getSlug(selectedCategory.value));
  }

  // Watch for query changes
  watch(
    () => props.query,
    (value) => {
      const lowercaseValue = value.toLowerCase();
      if (lowercaseValue.length > 0) {
        selectedCategory.value = props.tag?.name || props.tags[0]?.name || 'All';
        const matchingCategory = categories.value.find((category) => category.toLowerCase() === lowercaseValue);
        selectedCategory.value = matchingCategory || 'All';
      }
    }
  );
});

// Load more blogs
function onLoadMore() {
  fetchBlogs(getSlug(selectedCategory.value), ++page.value);
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  justify-content: center;
  height: auto;
  padding-bottom: 80px;
  margin-left: 0;
  gap: 16px;
}

.categories.centered-tab {
  justify-content: center;
}

.category-button {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background: #F0F0F0;

  white-space: nowrap;

  color: #242424;
  /* Body/Medium */
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  cursor: pointer;
}

.category-button.is-selected {
  background: #242424;
  color: #FFF;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 680px) {
  .categories {
    width: 100vw;
    overflow-x: scroll;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-bottom: 64px;
  }

  .category-button {
    font-size: 16px;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.25px;
  }

  .category-button:first-child {
    margin-left: 24px;
  }

  .category-button:last-child {
    margin-right: 24px;
  }
}
</style>
